/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Assistant:400,600,700");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #5f5073;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2378d6;
  --secondary: #283845;
  --success: #45b649;
  --info: #00c9ff;
  --warning: #ffd700;
  --danger: #d45d5d;
  --light: #fafafa;
  --dark: #343a40;
  --buttonBorder: #e0e0e0;
  --buttonBg: #fff;
  --header: #283845;
  --arrowGrey: #d1d1d6;
  --inputGrey: #bdbdbd;
  --white: #fff;
  --gray-100: #fafafa;
  --breadcrumb-bg: #fafafa;
  --formGrey: #757575;
  --input-placeholder-color: #bdbdbd;
  --input-plaintext-color: #424242;
  --purple: #5f5073;
  --aquamarine: #4fe9b3;
  --paleGreen: #c4d437;
  --paleOrange: #fa7b46;
  --lightBlueGrey: #f1f6fa;
  --darkBlueGrey: #283845;
  --bluishGrey: #7c8f9e;
  --transparent: rgba(0, 0, 0, 0);
  --behind: #fa7b46;
  --exceeding: #4fe9b3;
  --on-target: #2378d6;
  --button: #2378d6;
  --bluish: #2378d6;
  --paleRed: #d45d5d;
  --greyishBrown: #424242;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2378d6;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #185394;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c1d9f4; }

.table-hover .table-primary:hover {
  background-color: #abccf0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #abccf0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c3c7cb; }

.table-hover .table-secondary:hover {
  background-color: #b5babf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b5babf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cbebcc; }

.table-hover .table-success:hover {
  background-color: #b9e4ba; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b9e4ba; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8f0ff; }

.table-hover .table-info:hover {
  background-color: #9febff; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #9febff; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff4b8; }

.table-hover .table-warning:hover {
  background-color: #fff09f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff09f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f3d2d2; }

.table-hover .table-danger:hover {
  background-color: #eebebe; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eebebe; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-buttonBorder,
.table-buttonBorder > th,
.table-buttonBorder > td {
  background-color: #f6f6f6; }

.table-hover .table-buttonBorder:hover {
  background-color: #e9e9e9; }
  .table-hover .table-buttonBorder:hover > td,
  .table-hover .table-buttonBorder:hover > th {
    background-color: #e9e9e9; }

.table-buttonBg,
.table-buttonBg > th,
.table-buttonBg > td {
  background-color: white; }

.table-hover .table-buttonBg:hover {
  background-color: #f2f2f2; }
  .table-hover .table-buttonBg:hover > td,
  .table-hover .table-buttonBg:hover > th {
    background-color: #f2f2f2; }

.table-header,
.table-header > th,
.table-header > td {
  background-color: #c3c7cb; }

.table-hover .table-header:hover {
  background-color: #b5babf; }
  .table-hover .table-header:hover > td,
  .table-hover .table-header:hover > th {
    background-color: #b5babf; }

.table-arrowGrey,
.table-arrowGrey > th,
.table-arrowGrey > td {
  background-color: #f2f2f4; }

.table-hover .table-arrowGrey:hover {
  background-color: #e4e4e8; }
  .table-hover .table-arrowGrey:hover > td,
  .table-hover .table-arrowGrey:hover > th {
    background-color: #e4e4e8; }

.table-inputGrey,
.table-inputGrey > th,
.table-inputGrey > td {
  background-color: #ededed; }

.table-hover .table-inputGrey:hover {
  background-color: #e0e0e0; }
  .table-hover .table-inputGrey:hover > td,
  .table-hover .table-inputGrey:hover > th {
    background-color: #e0e0e0; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-gray-100,
.table-gray-100 > th,
.table-gray-100 > td {
  background-color: #fefefe; }

.table-hover .table-gray-100:hover {
  background-color: #f1f1f1; }
  .table-hover .table-gray-100:hover > td,
  .table-hover .table-gray-100:hover > th {
    background-color: #f1f1f1; }

.table-breadcrumb-bg,
.table-breadcrumb-bg > th,
.table-breadcrumb-bg > td {
  background-color: #fefefe; }

.table-hover .table-breadcrumb-bg:hover {
  background-color: #f1f1f1; }
  .table-hover .table-breadcrumb-bg:hover > td,
  .table-hover .table-breadcrumb-bg:hover > th {
    background-color: #f1f1f1; }

.table-formGrey,
.table-formGrey > th,
.table-formGrey > td {
  background-color: #d8d8d8; }

.table-hover .table-formGrey:hover {
  background-color: #cbcbcb; }
  .table-hover .table-formGrey:hover > td,
  .table-hover .table-formGrey:hover > th {
    background-color: #cbcbcb; }

.table-input-placeholder-color,
.table-input-placeholder-color > th,
.table-input-placeholder-color > td {
  background-color: #ededed; }

.table-hover .table-input-placeholder-color:hover {
  background-color: #e0e0e0; }
  .table-hover .table-input-placeholder-color:hover > td,
  .table-hover .table-input-placeholder-color:hover > th {
    background-color: #e0e0e0; }

.table-input-plaintext-color,
.table-input-plaintext-color > th,
.table-input-plaintext-color > td {
  background-color: #cacaca; }

.table-hover .table-input-plaintext-color:hover {
  background-color: #bdbdbd; }
  .table-hover .table-input-plaintext-color:hover > td,
  .table-hover .table-input-plaintext-color:hover > th {
    background-color: #bdbdbd; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #d2ced8; }

.table-hover .table-purple:hover {
  background-color: #c5c0cd; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #c5c0cd; }

.table-aquamarine,
.table-aquamarine > th,
.table-aquamarine > td {
  background-color: #cef9ea; }

.table-hover .table-aquamarine:hover {
  background-color: #b7f6e0; }
  .table-hover .table-aquamarine:hover > td,
  .table-hover .table-aquamarine:hover > th {
    background-color: #b7f6e0; }

.table-paleGreen,
.table-paleGreen > th,
.table-paleGreen > td {
  background-color: #eef3c7; }

.table-hover .table-paleGreen:hover {
  background-color: #e8efb2; }
  .table-hover .table-paleGreen:hover > td,
  .table-hover .table-paleGreen:hover > th {
    background-color: #e8efb2; }

.table-paleOrange,
.table-paleOrange > th,
.table-paleOrange > td {
  background-color: #fedacb; }

.table-hover .table-paleOrange:hover {
  background-color: #fec8b2; }
  .table-hover .table-paleOrange:hover > td,
  .table-hover .table-paleOrange:hover > th {
    background-color: #fec8b2; }

.table-lightBlueGrey,
.table-lightBlueGrey > th,
.table-lightBlueGrey > td {
  background-color: #fbfcfe; }

.table-hover .table-lightBlueGrey:hover {
  background-color: #e7edf9; }
  .table-hover .table-lightBlueGrey:hover > td,
  .table-hover .table-lightBlueGrey:hover > th {
    background-color: #e7edf9; }

.table-darkBlueGrey,
.table-darkBlueGrey > th,
.table-darkBlueGrey > td {
  background-color: #c3c7cb; }

.table-hover .table-darkBlueGrey:hover {
  background-color: #b5babf; }
  .table-hover .table-darkBlueGrey:hover > td,
  .table-hover .table-darkBlueGrey:hover > th {
    background-color: #b5babf; }

.table-bluishGrey,
.table-bluishGrey > th,
.table-bluishGrey > td {
  background-color: #dae0e4; }

.table-hover .table-bluishGrey:hover {
  background-color: #cbd4d9; }
  .table-hover .table-bluishGrey:hover > td,
  .table-hover .table-bluishGrey:hover > th {
    background-color: #cbd4d9; }

.table-transparent,
.table-transparent > th,
.table-transparent > td {
  background-color: rgba(255, 255, 255, 0.72); }

.table-hover .table-transparent:hover {
  background-color: rgba(242, 242, 242, 0.72); }
  .table-hover .table-transparent:hover > td,
  .table-hover .table-transparent:hover > th {
    background-color: rgba(242, 242, 242, 0.72); }

.table-behind,
.table-behind > th,
.table-behind > td {
  background-color: #fedacb; }

.table-hover .table-behind:hover {
  background-color: #fec8b2; }
  .table-hover .table-behind:hover > td,
  .table-hover .table-behind:hover > th {
    background-color: #fec8b2; }

.table-exceeding,
.table-exceeding > th,
.table-exceeding > td {
  background-color: #cef9ea; }

.table-hover .table-exceeding:hover {
  background-color: #b7f6e0; }
  .table-hover .table-exceeding:hover > td,
  .table-hover .table-exceeding:hover > th {
    background-color: #b7f6e0; }

.table-on-target,
.table-on-target > th,
.table-on-target > td {
  background-color: #c1d9f4; }

.table-hover .table-on-target:hover {
  background-color: #abccf0; }
  .table-hover .table-on-target:hover > td,
  .table-hover .table-on-target:hover > th {
    background-color: #abccf0; }

.table-button,
.table-button > th,
.table-button > td {
  background-color: #c1d9f4; }

.table-hover .table-button:hover {
  background-color: #abccf0; }
  .table-hover .table-button:hover > td,
  .table-hover .table-button:hover > th {
    background-color: #abccf0; }

.table-bluish,
.table-bluish > th,
.table-bluish > td {
  background-color: #c1d9f4; }

.table-hover .table-bluish:hover {
  background-color: #abccf0; }
  .table-hover .table-bluish:hover > td,
  .table-hover .table-bluish:hover > th {
    background-color: #abccf0; }

.table-paleRed,
.table-paleRed > th,
.table-paleRed > td {
  background-color: #f3d2d2; }

.table-hover .table-paleRed:hover {
  background-color: #eebebe; }
  .table-hover .table-paleRed:hover > td,
  .table-hover .table-paleRed:hover > th {
    background-color: #eebebe; }

.table-greyishBrown,
.table-greyishBrown > th,
.table-greyishBrown > td {
  background-color: #cacaca; }

.table-hover .table-greyishBrown:hover {
  background-color: #bdbdbd; }
  .table-hover .table-greyishBrown:hover > td,
  .table-hover .table-greyishBrown:hover > th {
    background-color: #bdbdbd; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #8cbaec;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.25); }
  .form-control::placeholder {
    color: #bdbdbd;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #424242;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #45b649; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(69, 182, 73, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #45b649; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #45b649;
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #45b649; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #45b649; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #a0dba2; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background: #68c66b linear-gradient(180deg, #7fce82, #68c66b) repeat-x; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #45b649; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d45d5d; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(212, 93, 93, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #d45d5d; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #d45d5d;
    box-shadow: 0 0 0 0.2rem rgba(212, 93, 93, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d45d5d; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d45d5d; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efc2c2; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background: #df8585 linear-gradient(180deg, #e49898, #df8585) repeat-x; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(212, 93, 93, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d45d5d; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(212, 93, 93, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x;
  border-color: #2378d6; }
  .btn-primary:hover {
    color: #fff;
    background: #1e66b5 linear-gradient(180deg, #3f7dc0, #1e66b5) repeat-x;
    border-color: #1c5faa; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1c5faa;
    background-image: none;
    border-color: #1a599f; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }

.btn-secondary {
  color: #fff;
  background: #283845 linear-gradient(180deg, #485661, #283845) repeat-x;
  border-color: #283845; }
  .btn-secondary:hover {
    color: #fff;
    background: #1a242d linear-gradient(180deg, #3c454c, #1a242d) repeat-x;
    border-color: #151e25; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #283845;
    border-color: #283845; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #151e25;
    background-image: none;
    border-color: #11171d; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }

.btn-success {
  color: #fff;
  background: #45b649 linear-gradient(180deg, #61c164, #45b649) repeat-x;
  border-color: #45b649; }
  .btn-success:hover {
    color: #fff;
    background: #3a9a3e linear-gradient(180deg, #58a95b, #3a9a3e) repeat-x;
    border-color: #37913a; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #45b649;
    border-color: #45b649; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #37913a;
    background-image: none;
    border-color: #338836; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }

.btn-info {
  color: #fff;
  background: #00c9ff linear-gradient(180deg, #26d1ff, #00c9ff) repeat-x;
  border-color: #00c9ff; }
  .btn-info:hover {
    color: #fff;
    background: #00abd9 linear-gradient(180deg, #26b7de, #00abd9) repeat-x;
    border-color: #00a1cc; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00c9ff;
    border-color: #00c9ff; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #00a1cc;
    background-image: none;
    border-color: #0097bf; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }

.btn-warning {
  color: #212529;
  background: #ffd700 linear-gradient(180deg, #ffdd26, #ffd700) repeat-x;
  border-color: #ffd700; }
  .btn-warning:hover {
    color: #212529;
    background: #d9b700 linear-gradient(180deg, #dec226, #d9b700) repeat-x;
    border-color: #ccac00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffd700;
    border-color: #ffd700; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ccac00;
    background-image: none;
    border-color: #bfa100; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }

.btn-danger {
  color: #fff;
  background: #d45d5d linear-gradient(180deg, #da7575, #d45d5d) repeat-x;
  border-color: #d45d5d; }
  .btn-danger:hover {
    color: #fff;
    background: #cc3f3f linear-gradient(180deg, #d45c5c, #cc3f3f) repeat-x;
    border-color: #c93535; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 93, 93, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #d45d5d;
    border-color: #d45d5d; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c93535;
    background-image: none;
    border-color: #bf3333; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 93, 93, 0.5); }

.btn-light {
  color: #212529;
  background: #fafafa linear-gradient(180deg, #fbfbfb, #fafafa) repeat-x;
  border-color: #fafafa; }
  .btn-light:hover {
    color: #212529;
    background: #e7e7e7 linear-gradient(180deg, #eaeaea, #e7e7e7) repeat-x;
    border-color: #e1e1e1; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e1e1e1;
    background-image: none;
    border-color: #dadada; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-dark {
  color: #fff;
  background: #343a40 linear-gradient(180deg, #52585d, #343a40) repeat-x;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background: #23272b linear-gradient(180deg, #44474b, #23272b) repeat-x;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    background-image: none;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-buttonBorder {
  color: #212529;
  background: #e0e0e0 linear-gradient(180deg, #e5e5e5, #e0e0e0) repeat-x;
  border-color: #e0e0e0; }
  .btn-buttonBorder:hover {
    color: #212529;
    background: #cdcdcd linear-gradient(180deg, #d4d4d4, #cdcdcd) repeat-x;
    border-color: #c7c7c7; }
  .btn-buttonBorder:focus, .btn-buttonBorder.focus {
    box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }
  .btn-buttonBorder.disabled, .btn-buttonBorder:disabled {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
  .btn-buttonBorder:not(:disabled):not(.disabled):active, .btn-buttonBorder:not(:disabled):not(.disabled).active,
  .show > .btn-buttonBorder.dropdown-toggle {
    color: #212529;
    background-color: #c7c7c7;
    background-image: none;
    border-color: silver; }
    .btn-buttonBorder:not(:disabled):not(.disabled):active:focus, .btn-buttonBorder:not(:disabled):not(.disabled).active:focus,
    .show > .btn-buttonBorder.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }

.btn-buttonBg {
  color: #212529;
  background: #fff linear-gradient(180deg, white, #fff) repeat-x;
  border-color: #fff; }
  .btn-buttonBg:hover {
    color: #212529;
    background: #ececec linear-gradient(180deg, #efefef, #ececec) repeat-x;
    border-color: #e6e6e6; }
  .btn-buttonBg:focus, .btn-buttonBg.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-buttonBg.disabled, .btn-buttonBg:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-buttonBg:not(:disabled):not(.disabled):active, .btn-buttonBg:not(:disabled):not(.disabled).active,
  .show > .btn-buttonBg.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #dfdfdf; }
    .btn-buttonBg:not(:disabled):not(.disabled):active:focus, .btn-buttonBg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-buttonBg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-header {
  color: #fff;
  background: #283845 linear-gradient(180deg, #485661, #283845) repeat-x;
  border-color: #283845; }
  .btn-header:hover {
    color: #fff;
    background: #1a242d linear-gradient(180deg, #3c454c, #1a242d) repeat-x;
    border-color: #151e25; }
  .btn-header:focus, .btn-header.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }
  .btn-header.disabled, .btn-header:disabled {
    color: #fff;
    background-color: #283845;
    border-color: #283845; }
  .btn-header:not(:disabled):not(.disabled):active, .btn-header:not(:disabled):not(.disabled).active,
  .show > .btn-header.dropdown-toggle {
    color: #fff;
    background-color: #151e25;
    background-image: none;
    border-color: #11171d; }
    .btn-header:not(:disabled):not(.disabled):active:focus, .btn-header:not(:disabled):not(.disabled).active:focus,
    .show > .btn-header.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }

.btn-arrowGrey {
  color: #212529;
  background: #d1d1d6 linear-gradient(180deg, #d8d8dc, #d1d1d6) repeat-x;
  border-color: #d1d1d6; }
  .btn-arrowGrey:hover {
    color: #212529;
    background: #bdbdc4 linear-gradient(180deg, #c7c7cd, #bdbdc4) repeat-x;
    border-color: #b6b6be; }
  .btn-arrowGrey:focus, .btn-arrowGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 209, 214, 0.5); }
  .btn-arrowGrey.disabled, .btn-arrowGrey:disabled {
    color: #212529;
    background-color: #d1d1d6;
    border-color: #d1d1d6; }
  .btn-arrowGrey:not(:disabled):not(.disabled):active, .btn-arrowGrey:not(:disabled):not(.disabled).active,
  .show > .btn-arrowGrey.dropdown-toggle {
    color: #212529;
    background-color: #b6b6be;
    background-image: none;
    border-color: #afafb8; }
    .btn-arrowGrey:not(:disabled):not(.disabled):active:focus, .btn-arrowGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-arrowGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(209, 209, 214, 0.5); }

.btn-inputGrey {
  color: #212529;
  background: #bdbdbd linear-gradient(180deg, #c7c7c7, #bdbdbd) repeat-x;
  border-color: #bdbdbd; }
  .btn-inputGrey:hover {
    color: #212529;
    background: #aaaaaa linear-gradient(180deg, #b7b7b7, #aaaaaa) repeat-x;
    border-color: #a4a4a4; }
  .btn-inputGrey:focus, .btn-inputGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(189, 189, 189, 0.5); }
  .btn-inputGrey.disabled, .btn-inputGrey:disabled {
    color: #212529;
    background-color: #bdbdbd;
    border-color: #bdbdbd; }
  .btn-inputGrey:not(:disabled):not(.disabled):active, .btn-inputGrey:not(:disabled):not(.disabled).active,
  .show > .btn-inputGrey.dropdown-toggle {
    color: #212529;
    background-color: #a4a4a4;
    background-image: none;
    border-color: #9d9d9d; }
    .btn-inputGrey:not(:disabled):not(.disabled):active:focus, .btn-inputGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-inputGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(189, 189, 189, 0.5); }

.btn-white {
  color: #212529;
  background: #fff linear-gradient(180deg, white, #fff) repeat-x;
  border-color: #fff; }
  .btn-white:hover {
    color: #212529;
    background: #ececec linear-gradient(180deg, #efefef, #ececec) repeat-x;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-gray-100 {
  color: #212529;
  background: #fafafa linear-gradient(180deg, #fbfbfb, #fafafa) repeat-x;
  border-color: #fafafa; }
  .btn-gray-100:hover {
    color: #212529;
    background: #e7e7e7 linear-gradient(180deg, #eaeaea, #e7e7e7) repeat-x;
    border-color: #e1e1e1; }
  .btn-gray-100:focus, .btn-gray-100.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #212529;
    background-color: #e1e1e1;
    background-image: none;
    border-color: #dadada; }
    .btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-breadcrumb-bg {
  color: #212529;
  background: #fafafa linear-gradient(180deg, #fbfbfb, #fafafa) repeat-x;
  border-color: #fafafa; }
  .btn-breadcrumb-bg:hover {
    color: #212529;
    background: #e7e7e7 linear-gradient(180deg, #eaeaea, #e7e7e7) repeat-x;
    border-color: #e1e1e1; }
  .btn-breadcrumb-bg:focus, .btn-breadcrumb-bg.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-breadcrumb-bg.disabled, .btn-breadcrumb-bg:disabled {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-breadcrumb-bg:not(:disabled):not(.disabled):active, .btn-breadcrumb-bg:not(:disabled):not(.disabled).active,
  .show > .btn-breadcrumb-bg.dropdown-toggle {
    color: #212529;
    background-color: #e1e1e1;
    background-image: none;
    border-color: #dadada; }
    .btn-breadcrumb-bg:not(:disabled):not(.disabled):active:focus, .btn-breadcrumb-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-breadcrumb-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-formGrey {
  color: #fff;
  background: #757575 linear-gradient(180deg, #8a8a8a, #757575) repeat-x;
  border-color: #757575; }
  .btn-formGrey:hover {
    color: #fff;
    background: #626262 linear-gradient(180deg, #797979, #626262) repeat-x;
    border-color: #5c5c5c; }
  .btn-formGrey:focus, .btn-formGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(117, 117, 117, 0.5); }
  .btn-formGrey.disabled, .btn-formGrey:disabled {
    color: #fff;
    background-color: #757575;
    border-color: #757575; }
  .btn-formGrey:not(:disabled):not(.disabled):active, .btn-formGrey:not(:disabled):not(.disabled).active,
  .show > .btn-formGrey.dropdown-toggle {
    color: #fff;
    background-color: #5c5c5c;
    background-image: none;
    border-color: #555555; }
    .btn-formGrey:not(:disabled):not(.disabled):active:focus, .btn-formGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-formGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(117, 117, 117, 0.5); }

.btn-input-placeholder-color {
  color: #212529;
  background: #bdbdbd linear-gradient(180deg, #c7c7c7, #bdbdbd) repeat-x;
  border-color: #bdbdbd; }
  .btn-input-placeholder-color:hover {
    color: #212529;
    background: #aaaaaa linear-gradient(180deg, #b7b7b7, #aaaaaa) repeat-x;
    border-color: #a4a4a4; }
  .btn-input-placeholder-color:focus, .btn-input-placeholder-color.focus {
    box-shadow: 0 0 0 0.2rem rgba(189, 189, 189, 0.5); }
  .btn-input-placeholder-color.disabled, .btn-input-placeholder-color:disabled {
    color: #212529;
    background-color: #bdbdbd;
    border-color: #bdbdbd; }
  .btn-input-placeholder-color:not(:disabled):not(.disabled):active, .btn-input-placeholder-color:not(:disabled):not(.disabled).active,
  .show > .btn-input-placeholder-color.dropdown-toggle {
    color: #212529;
    background-color: #a4a4a4;
    background-image: none;
    border-color: #9d9d9d; }
    .btn-input-placeholder-color:not(:disabled):not(.disabled):active:focus, .btn-input-placeholder-color:not(:disabled):not(.disabled).active:focus,
    .show > .btn-input-placeholder-color.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(189, 189, 189, 0.5); }

.btn-input-plaintext-color {
  color: #fff;
  background: #424242 linear-gradient(180deg, #5e5e5e, #424242) repeat-x;
  border-color: #424242; }
  .btn-input-plaintext-color:hover {
    color: #fff;
    background: #2f2f2f linear-gradient(180deg, #4e4e4e, #2f2f2f) repeat-x;
    border-color: #292929; }
  .btn-input-plaintext-color:focus, .btn-input-plaintext-color.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-input-plaintext-color.disabled, .btn-input-plaintext-color:disabled {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
  .btn-input-plaintext-color:not(:disabled):not(.disabled):active, .btn-input-plaintext-color:not(:disabled):not(.disabled).active,
  .show > .btn-input-plaintext-color.dropdown-toggle {
    color: #fff;
    background-color: #292929;
    background-image: none;
    border-color: #222222; }
    .btn-input-plaintext-color:not(:disabled):not(.disabled):active:focus, .btn-input-plaintext-color:not(:disabled):not(.disabled).active:focus,
    .show > .btn-input-plaintext-color.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-purple {
  color: #fff;
  background: #5f5073 linear-gradient(180deg, #776a88, #5f5073) repeat-x;
  border-color: #5f5073; }
  .btn-purple:hover {
    color: #fff;
    background: #4c405c linear-gradient(180deg, #675d75, #4c405c) repeat-x;
    border-color: #463b55; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(95, 80, 115, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #5f5073;
    border-color: #5f5073; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #463b55;
    background-image: none;
    border-color: #40364d; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(95, 80, 115, 0.5); }

.btn-aquamarine {
  color: #212529;
  background: #4fe9b3 linear-gradient(180deg, #69ecbe, #4fe9b3) repeat-x;
  border-color: #4fe9b3; }
  .btn-aquamarine:hover {
    color: #212529;
    background: #2de5a4 linear-gradient(180deg, #4de9b2, #2de5a4) repeat-x;
    border-color: #22e39f; }
  .btn-aquamarine:focus, .btn-aquamarine.focus {
    box-shadow: 0 0 0 0.2rem rgba(79, 233, 179, 0.5); }
  .btn-aquamarine.disabled, .btn-aquamarine:disabled {
    color: #212529;
    background-color: #4fe9b3;
    border-color: #4fe9b3; }
  .btn-aquamarine:not(:disabled):not(.disabled):active, .btn-aquamarine:not(:disabled):not(.disabled).active,
  .show > .btn-aquamarine.dropdown-toggle {
    color: #212529;
    background-color: #22e39f;
    background-image: none;
    border-color: #1cdd99; }
    .btn-aquamarine:not(:disabled):not(.disabled):active:focus, .btn-aquamarine:not(:disabled):not(.disabled).active:focus,
    .show > .btn-aquamarine.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(79, 233, 179, 0.5); }

.btn-paleGreen {
  color: #212529;
  background: #c4d437 linear-gradient(180deg, #cdda55, #c4d437) repeat-x;
  border-color: #c4d437; }
  .btn-paleGreen:hover {
    color: #212529;
    background: #adbc28 linear-gradient(180deg, #b9c649, #adbc28) repeat-x;
    border-color: #a4b226; }
  .btn-paleGreen:focus, .btn-paleGreen.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 212, 55, 0.5); }
  .btn-paleGreen.disabled, .btn-paleGreen:disabled {
    color: #212529;
    background-color: #c4d437;
    border-color: #c4d437; }
  .btn-paleGreen:not(:disabled):not(.disabled):active, .btn-paleGreen:not(:disabled):not(.disabled).active,
  .show > .btn-paleGreen.dropdown-toggle {
    color: #212529;
    background-color: #a4b226;
    background-image: none;
    border-color: #9aa724; }
    .btn-paleGreen:not(:disabled):not(.disabled):active:focus, .btn-paleGreen:not(:disabled):not(.disabled).active:focus,
    .show > .btn-paleGreen.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 212, 55, 0.5); }

.btn-paleOrange {
  color: #212529;
  background: #fa7b46 linear-gradient(180deg, #fb8f62, #fa7b46) repeat-x;
  border-color: #fa7b46; }
  .btn-paleOrange:hover {
    color: #fff;
    background: #f96021 linear-gradient(180deg, #fa7842, #f96021) repeat-x;
    border-color: #f95814; }
  .btn-paleOrange:focus, .btn-paleOrange.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 123, 70, 0.5); }
  .btn-paleOrange.disabled, .btn-paleOrange:disabled {
    color: #212529;
    background-color: #fa7b46;
    border-color: #fa7b46; }
  .btn-paleOrange:not(:disabled):not(.disabled):active, .btn-paleOrange:not(:disabled):not(.disabled).active,
  .show > .btn-paleOrange.dropdown-toggle {
    color: #fff;
    background-color: #f95814;
    background-image: none;
    border-color: #f84f08; }
    .btn-paleOrange:not(:disabled):not(.disabled):active:focus, .btn-paleOrange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-paleOrange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 123, 70, 0.5); }

.btn-lightBlueGrey {
  color: #212529;
  background: #f1f6fa linear-gradient(180deg, #f3f7fb, #f1f6fa) repeat-x;
  border-color: #f1f6fa; }
  .btn-lightBlueGrey:hover {
    color: #212529;
    background: #d5e4f0 linear-gradient(180deg, #dbe8f2, #d5e4f0) repeat-x;
    border-color: #cbdeed; }
  .btn-lightBlueGrey:focus, .btn-lightBlueGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 246, 250, 0.5); }
  .btn-lightBlueGrey.disabled, .btn-lightBlueGrey:disabled {
    color: #212529;
    background-color: #f1f6fa;
    border-color: #f1f6fa; }
  .btn-lightBlueGrey:not(:disabled):not(.disabled):active, .btn-lightBlueGrey:not(:disabled):not(.disabled).active,
  .show > .btn-lightBlueGrey.dropdown-toggle {
    color: #212529;
    background-color: #cbdeed;
    background-image: none;
    border-color: #c2d8e9; }
    .btn-lightBlueGrey:not(:disabled):not(.disabled):active:focus, .btn-lightBlueGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-lightBlueGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 246, 250, 0.5); }

.btn-darkBlueGrey {
  color: #fff;
  background: #283845 linear-gradient(180deg, #485661, #283845) repeat-x;
  border-color: #283845; }
  .btn-darkBlueGrey:hover {
    color: #fff;
    background: #1a242d linear-gradient(180deg, #3c454c, #1a242d) repeat-x;
    border-color: #151e25; }
  .btn-darkBlueGrey:focus, .btn-darkBlueGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }
  .btn-darkBlueGrey.disabled, .btn-darkBlueGrey:disabled {
    color: #fff;
    background-color: #283845;
    border-color: #283845; }
  .btn-darkBlueGrey:not(:disabled):not(.disabled):active, .btn-darkBlueGrey:not(:disabled):not(.disabled).active,
  .show > .btn-darkBlueGrey.dropdown-toggle {
    color: #fff;
    background-color: #151e25;
    background-image: none;
    border-color: #11171d; }
    .btn-darkBlueGrey:not(:disabled):not(.disabled):active:focus, .btn-darkBlueGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-darkBlueGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }

.btn-bluishGrey {
  color: #fff;
  background: #7c8f9e linear-gradient(180deg, #90a0ad, #7c8f9e) repeat-x;
  border-color: #7c8f9e; }
  .btn-bluishGrey:hover {
    color: #fff;
    background: #687c8c linear-gradient(180deg, #7e909d, #687c8c) repeat-x;
    border-color: #627685; }
  .btn-bluishGrey:focus, .btn-bluishGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(124, 143, 158, 0.5); }
  .btn-bluishGrey.disabled, .btn-bluishGrey:disabled {
    color: #fff;
    background-color: #7c8f9e;
    border-color: #7c8f9e; }
  .btn-bluishGrey:not(:disabled):not(.disabled):active, .btn-bluishGrey:not(:disabled):not(.disabled).active,
  .show > .btn-bluishGrey.dropdown-toggle {
    color: #fff;
    background-color: #627685;
    background-image: none;
    border-color: #5d6f7d; }
    .btn-bluishGrey:not(:disabled):not(.disabled):active:focus, .btn-bluishGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-bluishGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(124, 143, 158, 0.5); }

.btn-transparent {
  color: #fff;
  background: rgba(0, 0, 0, 0) linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0)) repeat-x;
  border-color: rgba(0, 0, 0, 0); }
  .btn-transparent:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0) linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0)) repeat-x;
    border-color: rgba(0, 0, 0, 0); }
  .btn-transparent:focus, .btn-transparent.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-transparent.disabled, .btn-transparent:disabled {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0); }
  .btn-transparent:not(:disabled):not(.disabled):active, .btn-transparent:not(:disabled):not(.disabled).active,
  .show > .btn-transparent.dropdown-toggle {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: rgba(0, 0, 0, 0); }
    .btn-transparent:not(:disabled):not(.disabled):active:focus, .btn-transparent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-transparent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-behind {
  color: #212529;
  background: #fa7b46 linear-gradient(180deg, #fb8f62, #fa7b46) repeat-x;
  border-color: #fa7b46; }
  .btn-behind:hover {
    color: #fff;
    background: #f96021 linear-gradient(180deg, #fa7842, #f96021) repeat-x;
    border-color: #f95814; }
  .btn-behind:focus, .btn-behind.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 123, 70, 0.5); }
  .btn-behind.disabled, .btn-behind:disabled {
    color: #212529;
    background-color: #fa7b46;
    border-color: #fa7b46; }
  .btn-behind:not(:disabled):not(.disabled):active, .btn-behind:not(:disabled):not(.disabled).active,
  .show > .btn-behind.dropdown-toggle {
    color: #fff;
    background-color: #f95814;
    background-image: none;
    border-color: #f84f08; }
    .btn-behind:not(:disabled):not(.disabled):active:focus, .btn-behind:not(:disabled):not(.disabled).active:focus,
    .show > .btn-behind.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 123, 70, 0.5); }

.btn-exceeding {
  color: #212529;
  background: #4fe9b3 linear-gradient(180deg, #69ecbe, #4fe9b3) repeat-x;
  border-color: #4fe9b3; }
  .btn-exceeding:hover {
    color: #212529;
    background: #2de5a4 linear-gradient(180deg, #4de9b2, #2de5a4) repeat-x;
    border-color: #22e39f; }
  .btn-exceeding:focus, .btn-exceeding.focus {
    box-shadow: 0 0 0 0.2rem rgba(79, 233, 179, 0.5); }
  .btn-exceeding.disabled, .btn-exceeding:disabled {
    color: #212529;
    background-color: #4fe9b3;
    border-color: #4fe9b3; }
  .btn-exceeding:not(:disabled):not(.disabled):active, .btn-exceeding:not(:disabled):not(.disabled).active,
  .show > .btn-exceeding.dropdown-toggle {
    color: #212529;
    background-color: #22e39f;
    background-image: none;
    border-color: #1cdd99; }
    .btn-exceeding:not(:disabled):not(.disabled):active:focus, .btn-exceeding:not(:disabled):not(.disabled).active:focus,
    .show > .btn-exceeding.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(79, 233, 179, 0.5); }

.btn-on-target {
  color: #fff;
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x;
  border-color: #2378d6; }
  .btn-on-target:hover {
    color: #fff;
    background: #1e66b5 linear-gradient(180deg, #3f7dc0, #1e66b5) repeat-x;
    border-color: #1c5faa; }
  .btn-on-target:focus, .btn-on-target.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }
  .btn-on-target.disabled, .btn-on-target:disabled {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
  .btn-on-target:not(:disabled):not(.disabled):active, .btn-on-target:not(:disabled):not(.disabled).active,
  .show > .btn-on-target.dropdown-toggle {
    color: #fff;
    background-color: #1c5faa;
    background-image: none;
    border-color: #1a599f; }
    .btn-on-target:not(:disabled):not(.disabled):active:focus, .btn-on-target:not(:disabled):not(.disabled).active:focus,
    .show > .btn-on-target.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }

.btn-button {
  color: #fff;
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x;
  border-color: #2378d6; }
  .btn-button:hover {
    color: #fff;
    background: #1e66b5 linear-gradient(180deg, #3f7dc0, #1e66b5) repeat-x;
    border-color: #1c5faa; }
  .btn-button:focus, .btn-button.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }
  .btn-button.disabled, .btn-button:disabled {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
  .btn-button:not(:disabled):not(.disabled):active, .btn-button:not(:disabled):not(.disabled).active,
  .show > .btn-button.dropdown-toggle {
    color: #fff;
    background-color: #1c5faa;
    background-image: none;
    border-color: #1a599f; }
    .btn-button:not(:disabled):not(.disabled):active:focus, .btn-button:not(:disabled):not(.disabled).active:focus,
    .show > .btn-button.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }

.btn-bluish {
  color: #fff;
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x;
  border-color: #2378d6; }
  .btn-bluish:hover {
    color: #fff;
    background: #1e66b5 linear-gradient(180deg, #3f7dc0, #1e66b5) repeat-x;
    border-color: #1c5faa; }
  .btn-bluish:focus, .btn-bluish.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }
  .btn-bluish.disabled, .btn-bluish:disabled {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
  .btn-bluish:not(:disabled):not(.disabled):active, .btn-bluish:not(:disabled):not(.disabled).active,
  .show > .btn-bluish.dropdown-toggle {
    color: #fff;
    background-color: #1c5faa;
    background-image: none;
    border-color: #1a599f; }
    .btn-bluish:not(:disabled):not(.disabled):active:focus, .btn-bluish:not(:disabled):not(.disabled).active:focus,
    .show > .btn-bluish.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }

.btn-paleRed {
  color: #fff;
  background: #d45d5d linear-gradient(180deg, #da7575, #d45d5d) repeat-x;
  border-color: #d45d5d; }
  .btn-paleRed:hover {
    color: #fff;
    background: #cc3f3f linear-gradient(180deg, #d45c5c, #cc3f3f) repeat-x;
    border-color: #c93535; }
  .btn-paleRed:focus, .btn-paleRed.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 93, 93, 0.5); }
  .btn-paleRed.disabled, .btn-paleRed:disabled {
    color: #fff;
    background-color: #d45d5d;
    border-color: #d45d5d; }
  .btn-paleRed:not(:disabled):not(.disabled):active, .btn-paleRed:not(:disabled):not(.disabled).active,
  .show > .btn-paleRed.dropdown-toggle {
    color: #fff;
    background-color: #c93535;
    background-image: none;
    border-color: #bf3333; }
    .btn-paleRed:not(:disabled):not(.disabled):active:focus, .btn-paleRed:not(:disabled):not(.disabled).active:focus,
    .show > .btn-paleRed.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 93, 93, 0.5); }

.btn-greyishBrown {
  color: #fff;
  background: #424242 linear-gradient(180deg, #5e5e5e, #424242) repeat-x;
  border-color: #424242; }
  .btn-greyishBrown:hover {
    color: #fff;
    background: #2f2f2f linear-gradient(180deg, #4e4e4e, #2f2f2f) repeat-x;
    border-color: #292929; }
  .btn-greyishBrown:focus, .btn-greyishBrown.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-greyishBrown.disabled, .btn-greyishBrown:disabled {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
  .btn-greyishBrown:not(:disabled):not(.disabled):active, .btn-greyishBrown:not(:disabled):not(.disabled).active,
  .show > .btn-greyishBrown.dropdown-toggle {
    color: #fff;
    background-color: #292929;
    background-image: none;
    border-color: #222222; }
    .btn-greyishBrown:not(:disabled):not(.disabled):active:focus, .btn-greyishBrown:not(:disabled):not(.disabled).active:focus,
    .show > .btn-greyishBrown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-outline-primary {
  color: #2378d6;
  background-color: transparent;
  background-image: none;
  border-color: #2378d6; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2378d6;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }

.btn-outline-secondary {
  color: #283845;
  background-color: transparent;
  background-image: none;
  border-color: #283845; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #283845;
    border-color: #283845; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #283845;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #283845;
    border-color: #283845; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }

.btn-outline-success {
  color: #45b649;
  background-color: transparent;
  background-image: none;
  border-color: #45b649; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #45b649;
    border-color: #45b649; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #45b649;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #45b649;
    border-color: #45b649; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 182, 73, 0.5); }

.btn-outline-info {
  color: #00c9ff;
  background-color: transparent;
  background-image: none;
  border-color: #00c9ff; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00c9ff;
    border-color: #00c9ff; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00c9ff;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00c9ff;
    border-color: #00c9ff; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 201, 255, 0.5); }

.btn-outline-warning {
  color: #ffd700;
  background-color: transparent;
  background-image: none;
  border-color: #ffd700; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffd700;
    border-color: #ffd700; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffd700;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffd700;
    border-color: #ffd700; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 215, 0, 0.5); }

.btn-outline-danger {
  color: #d45d5d;
  background-color: transparent;
  background-image: none;
  border-color: #d45d5d; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d45d5d;
    border-color: #d45d5d; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 93, 93, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d45d5d;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d45d5d;
    border-color: #d45d5d; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 93, 93, 0.5); }

.btn-outline-light {
  color: #fafafa;
  background-color: transparent;
  background-image: none;
  border-color: #fafafa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fafafa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-buttonBorder {
  color: #e0e0e0;
  background-color: transparent;
  background-image: none;
  border-color: #e0e0e0; }
  .btn-outline-buttonBorder:hover {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
  .btn-outline-buttonBorder:focus, .btn-outline-buttonBorder.focus {
    box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }
  .btn-outline-buttonBorder.disabled, .btn-outline-buttonBorder:disabled {
    color: #e0e0e0;
    background-color: transparent; }
  .btn-outline-buttonBorder:not(:disabled):not(.disabled):active, .btn-outline-buttonBorder:not(:disabled):not(.disabled).active,
  .show > .btn-outline-buttonBorder.dropdown-toggle {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
    .btn-outline-buttonBorder:not(:disabled):not(.disabled):active:focus, .btn-outline-buttonBorder:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-buttonBorder.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }

.btn-outline-buttonBg {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff; }
  .btn-outline-buttonBg:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-buttonBg:focus, .btn-outline-buttonBg.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-buttonBg.disabled, .btn-outline-buttonBg:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-buttonBg:not(:disabled):not(.disabled):active, .btn-outline-buttonBg:not(:disabled):not(.disabled).active,
  .show > .btn-outline-buttonBg.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-buttonBg:not(:disabled):not(.disabled):active:focus, .btn-outline-buttonBg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-buttonBg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-header {
  color: #283845;
  background-color: transparent;
  background-image: none;
  border-color: #283845; }
  .btn-outline-header:hover {
    color: #fff;
    background-color: #283845;
    border-color: #283845; }
  .btn-outline-header:focus, .btn-outline-header.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }
  .btn-outline-header.disabled, .btn-outline-header:disabled {
    color: #283845;
    background-color: transparent; }
  .btn-outline-header:not(:disabled):not(.disabled):active, .btn-outline-header:not(:disabled):not(.disabled).active,
  .show > .btn-outline-header.dropdown-toggle {
    color: #fff;
    background-color: #283845;
    border-color: #283845; }
    .btn-outline-header:not(:disabled):not(.disabled):active:focus, .btn-outline-header:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-header.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }

.btn-outline-arrowGrey {
  color: #d1d1d6;
  background-color: transparent;
  background-image: none;
  border-color: #d1d1d6; }
  .btn-outline-arrowGrey:hover {
    color: #212529;
    background-color: #d1d1d6;
    border-color: #d1d1d6; }
  .btn-outline-arrowGrey:focus, .btn-outline-arrowGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 209, 214, 0.5); }
  .btn-outline-arrowGrey.disabled, .btn-outline-arrowGrey:disabled {
    color: #d1d1d6;
    background-color: transparent; }
  .btn-outline-arrowGrey:not(:disabled):not(.disabled):active, .btn-outline-arrowGrey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-arrowGrey.dropdown-toggle {
    color: #212529;
    background-color: #d1d1d6;
    border-color: #d1d1d6; }
    .btn-outline-arrowGrey:not(:disabled):not(.disabled):active:focus, .btn-outline-arrowGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-arrowGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(209, 209, 214, 0.5); }

.btn-outline-inputGrey {
  color: #bdbdbd;
  background-color: transparent;
  background-image: none;
  border-color: #bdbdbd; }
  .btn-outline-inputGrey:hover {
    color: #212529;
    background-color: #bdbdbd;
    border-color: #bdbdbd; }
  .btn-outline-inputGrey:focus, .btn-outline-inputGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(189, 189, 189, 0.5); }
  .btn-outline-inputGrey.disabled, .btn-outline-inputGrey:disabled {
    color: #bdbdbd;
    background-color: transparent; }
  .btn-outline-inputGrey:not(:disabled):not(.disabled):active, .btn-outline-inputGrey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-inputGrey.dropdown-toggle {
    color: #212529;
    background-color: #bdbdbd;
    border-color: #bdbdbd; }
    .btn-outline-inputGrey:not(:disabled):not(.disabled):active:focus, .btn-outline-inputGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-inputGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(189, 189, 189, 0.5); }

.btn-outline-white {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-gray-100 {
  color: #fafafa;
  background-color: transparent;
  background-image: none;
  border-color: #fafafa; }
  .btn-outline-gray-100:hover {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
    color: #fafafa;
    background-color: transparent; }
  .btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-100.dropdown-toggle {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
    .btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-outline-breadcrumb-bg {
  color: #fafafa;
  background-color: transparent;
  background-image: none;
  border-color: #fafafa; }
  .btn-outline-breadcrumb-bg:hover {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-outline-breadcrumb-bg:focus, .btn-outline-breadcrumb-bg.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-outline-breadcrumb-bg.disabled, .btn-outline-breadcrumb-bg:disabled {
    color: #fafafa;
    background-color: transparent; }
  .btn-outline-breadcrumb-bg:not(:disabled):not(.disabled):active, .btn-outline-breadcrumb-bg:not(:disabled):not(.disabled).active,
  .show > .btn-outline-breadcrumb-bg.dropdown-toggle {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
    .btn-outline-breadcrumb-bg:not(:disabled):not(.disabled):active:focus, .btn-outline-breadcrumb-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-breadcrumb-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-outline-formGrey {
  color: #757575;
  background-color: transparent;
  background-image: none;
  border-color: #757575; }
  .btn-outline-formGrey:hover {
    color: #fff;
    background-color: #757575;
    border-color: #757575; }
  .btn-outline-formGrey:focus, .btn-outline-formGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(117, 117, 117, 0.5); }
  .btn-outline-formGrey.disabled, .btn-outline-formGrey:disabled {
    color: #757575;
    background-color: transparent; }
  .btn-outline-formGrey:not(:disabled):not(.disabled):active, .btn-outline-formGrey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-formGrey.dropdown-toggle {
    color: #fff;
    background-color: #757575;
    border-color: #757575; }
    .btn-outline-formGrey:not(:disabled):not(.disabled):active:focus, .btn-outline-formGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-formGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(117, 117, 117, 0.5); }

.btn-outline-input-placeholder-color {
  color: #bdbdbd;
  background-color: transparent;
  background-image: none;
  border-color: #bdbdbd; }
  .btn-outline-input-placeholder-color:hover {
    color: #212529;
    background-color: #bdbdbd;
    border-color: #bdbdbd; }
  .btn-outline-input-placeholder-color:focus, .btn-outline-input-placeholder-color.focus {
    box-shadow: 0 0 0 0.2rem rgba(189, 189, 189, 0.5); }
  .btn-outline-input-placeholder-color.disabled, .btn-outline-input-placeholder-color:disabled {
    color: #bdbdbd;
    background-color: transparent; }
  .btn-outline-input-placeholder-color:not(:disabled):not(.disabled):active, .btn-outline-input-placeholder-color:not(:disabled):not(.disabled).active,
  .show > .btn-outline-input-placeholder-color.dropdown-toggle {
    color: #212529;
    background-color: #bdbdbd;
    border-color: #bdbdbd; }
    .btn-outline-input-placeholder-color:not(:disabled):not(.disabled):active:focus, .btn-outline-input-placeholder-color:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-input-placeholder-color.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(189, 189, 189, 0.5); }

.btn-outline-input-plaintext-color {
  color: #424242;
  background-color: transparent;
  background-image: none;
  border-color: #424242; }
  .btn-outline-input-plaintext-color:hover {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
  .btn-outline-input-plaintext-color:focus, .btn-outline-input-plaintext-color.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-outline-input-plaintext-color.disabled, .btn-outline-input-plaintext-color:disabled {
    color: #424242;
    background-color: transparent; }
  .btn-outline-input-plaintext-color:not(:disabled):not(.disabled):active, .btn-outline-input-plaintext-color:not(:disabled):not(.disabled).active,
  .show > .btn-outline-input-plaintext-color.dropdown-toggle {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
    .btn-outline-input-plaintext-color:not(:disabled):not(.disabled):active:focus, .btn-outline-input-plaintext-color:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-input-plaintext-color.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-outline-purple {
  color: #5f5073;
  background-color: transparent;
  background-image: none;
  border-color: #5f5073; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #5f5073;
    border-color: #5f5073; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(95, 80, 115, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #5f5073;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #5f5073;
    border-color: #5f5073; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(95, 80, 115, 0.5); }

.btn-outline-aquamarine {
  color: #4fe9b3;
  background-color: transparent;
  background-image: none;
  border-color: #4fe9b3; }
  .btn-outline-aquamarine:hover {
    color: #212529;
    background-color: #4fe9b3;
    border-color: #4fe9b3; }
  .btn-outline-aquamarine:focus, .btn-outline-aquamarine.focus {
    box-shadow: 0 0 0 0.2rem rgba(79, 233, 179, 0.5); }
  .btn-outline-aquamarine.disabled, .btn-outline-aquamarine:disabled {
    color: #4fe9b3;
    background-color: transparent; }
  .btn-outline-aquamarine:not(:disabled):not(.disabled):active, .btn-outline-aquamarine:not(:disabled):not(.disabled).active,
  .show > .btn-outline-aquamarine.dropdown-toggle {
    color: #212529;
    background-color: #4fe9b3;
    border-color: #4fe9b3; }
    .btn-outline-aquamarine:not(:disabled):not(.disabled):active:focus, .btn-outline-aquamarine:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-aquamarine.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(79, 233, 179, 0.5); }

.btn-outline-paleGreen {
  color: #c4d437;
  background-color: transparent;
  background-image: none;
  border-color: #c4d437; }
  .btn-outline-paleGreen:hover {
    color: #212529;
    background-color: #c4d437;
    border-color: #c4d437; }
  .btn-outline-paleGreen:focus, .btn-outline-paleGreen.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 212, 55, 0.5); }
  .btn-outline-paleGreen.disabled, .btn-outline-paleGreen:disabled {
    color: #c4d437;
    background-color: transparent; }
  .btn-outline-paleGreen:not(:disabled):not(.disabled):active, .btn-outline-paleGreen:not(:disabled):not(.disabled).active,
  .show > .btn-outline-paleGreen.dropdown-toggle {
    color: #212529;
    background-color: #c4d437;
    border-color: #c4d437; }
    .btn-outline-paleGreen:not(:disabled):not(.disabled):active:focus, .btn-outline-paleGreen:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-paleGreen.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 212, 55, 0.5); }

.btn-outline-paleOrange {
  color: #fa7b46;
  background-color: transparent;
  background-image: none;
  border-color: #fa7b46; }
  .btn-outline-paleOrange:hover {
    color: #212529;
    background-color: #fa7b46;
    border-color: #fa7b46; }
  .btn-outline-paleOrange:focus, .btn-outline-paleOrange.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 123, 70, 0.5); }
  .btn-outline-paleOrange.disabled, .btn-outline-paleOrange:disabled {
    color: #fa7b46;
    background-color: transparent; }
  .btn-outline-paleOrange:not(:disabled):not(.disabled):active, .btn-outline-paleOrange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-paleOrange.dropdown-toggle {
    color: #212529;
    background-color: #fa7b46;
    border-color: #fa7b46; }
    .btn-outline-paleOrange:not(:disabled):not(.disabled):active:focus, .btn-outline-paleOrange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-paleOrange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 123, 70, 0.5); }

.btn-outline-lightBlueGrey {
  color: #f1f6fa;
  background-color: transparent;
  background-image: none;
  border-color: #f1f6fa; }
  .btn-outline-lightBlueGrey:hover {
    color: #212529;
    background-color: #f1f6fa;
    border-color: #f1f6fa; }
  .btn-outline-lightBlueGrey:focus, .btn-outline-lightBlueGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 246, 250, 0.5); }
  .btn-outline-lightBlueGrey.disabled, .btn-outline-lightBlueGrey:disabled {
    color: #f1f6fa;
    background-color: transparent; }
  .btn-outline-lightBlueGrey:not(:disabled):not(.disabled):active, .btn-outline-lightBlueGrey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-lightBlueGrey.dropdown-toggle {
    color: #212529;
    background-color: #f1f6fa;
    border-color: #f1f6fa; }
    .btn-outline-lightBlueGrey:not(:disabled):not(.disabled):active:focus, .btn-outline-lightBlueGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-lightBlueGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 246, 250, 0.5); }

.btn-outline-darkBlueGrey {
  color: #283845;
  background-color: transparent;
  background-image: none;
  border-color: #283845; }
  .btn-outline-darkBlueGrey:hover {
    color: #fff;
    background-color: #283845;
    border-color: #283845; }
  .btn-outline-darkBlueGrey:focus, .btn-outline-darkBlueGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }
  .btn-outline-darkBlueGrey.disabled, .btn-outline-darkBlueGrey:disabled {
    color: #283845;
    background-color: transparent; }
  .btn-outline-darkBlueGrey:not(:disabled):not(.disabled):active, .btn-outline-darkBlueGrey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-darkBlueGrey.dropdown-toggle {
    color: #fff;
    background-color: #283845;
    border-color: #283845; }
    .btn-outline-darkBlueGrey:not(:disabled):not(.disabled):active:focus, .btn-outline-darkBlueGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-darkBlueGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 56, 69, 0.5); }

.btn-outline-bluishGrey {
  color: #7c8f9e;
  background-color: transparent;
  background-image: none;
  border-color: #7c8f9e; }
  .btn-outline-bluishGrey:hover {
    color: #fff;
    background-color: #7c8f9e;
    border-color: #7c8f9e; }
  .btn-outline-bluishGrey:focus, .btn-outline-bluishGrey.focus {
    box-shadow: 0 0 0 0.2rem rgba(124, 143, 158, 0.5); }
  .btn-outline-bluishGrey.disabled, .btn-outline-bluishGrey:disabled {
    color: #7c8f9e;
    background-color: transparent; }
  .btn-outline-bluishGrey:not(:disabled):not(.disabled):active, .btn-outline-bluishGrey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-bluishGrey.dropdown-toggle {
    color: #fff;
    background-color: #7c8f9e;
    border-color: #7c8f9e; }
    .btn-outline-bluishGrey:not(:disabled):not(.disabled):active:focus, .btn-outline-bluishGrey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-bluishGrey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(124, 143, 158, 0.5); }

.btn-outline-transparent {
  color: rgba(0, 0, 0, 0);
  background-color: transparent;
  background-image: none;
  border-color: rgba(0, 0, 0, 0); }
  .btn-outline-transparent:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0); }
  .btn-outline-transparent:focus, .btn-outline-transparent.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-transparent.disabled, .btn-outline-transparent:disabled {
    color: rgba(0, 0, 0, 0);
    background-color: transparent; }
  .btn-outline-transparent:not(:disabled):not(.disabled):active, .btn-outline-transparent:not(:disabled):not(.disabled).active,
  .show > .btn-outline-transparent.dropdown-toggle {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0); }
    .btn-outline-transparent:not(:disabled):not(.disabled):active:focus, .btn-outline-transparent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-transparent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-behind {
  color: #fa7b46;
  background-color: transparent;
  background-image: none;
  border-color: #fa7b46; }
  .btn-outline-behind:hover {
    color: #212529;
    background-color: #fa7b46;
    border-color: #fa7b46; }
  .btn-outline-behind:focus, .btn-outline-behind.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 123, 70, 0.5); }
  .btn-outline-behind.disabled, .btn-outline-behind:disabled {
    color: #fa7b46;
    background-color: transparent; }
  .btn-outline-behind:not(:disabled):not(.disabled):active, .btn-outline-behind:not(:disabled):not(.disabled).active,
  .show > .btn-outline-behind.dropdown-toggle {
    color: #212529;
    background-color: #fa7b46;
    border-color: #fa7b46; }
    .btn-outline-behind:not(:disabled):not(.disabled):active:focus, .btn-outline-behind:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-behind.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 123, 70, 0.5); }

.btn-outline-exceeding {
  color: #4fe9b3;
  background-color: transparent;
  background-image: none;
  border-color: #4fe9b3; }
  .btn-outline-exceeding:hover {
    color: #212529;
    background-color: #4fe9b3;
    border-color: #4fe9b3; }
  .btn-outline-exceeding:focus, .btn-outline-exceeding.focus {
    box-shadow: 0 0 0 0.2rem rgba(79, 233, 179, 0.5); }
  .btn-outline-exceeding.disabled, .btn-outline-exceeding:disabled {
    color: #4fe9b3;
    background-color: transparent; }
  .btn-outline-exceeding:not(:disabled):not(.disabled):active, .btn-outline-exceeding:not(:disabled):not(.disabled).active,
  .show > .btn-outline-exceeding.dropdown-toggle {
    color: #212529;
    background-color: #4fe9b3;
    border-color: #4fe9b3; }
    .btn-outline-exceeding:not(:disabled):not(.disabled):active:focus, .btn-outline-exceeding:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-exceeding.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(79, 233, 179, 0.5); }

.btn-outline-on-target {
  color: #2378d6;
  background-color: transparent;
  background-image: none;
  border-color: #2378d6; }
  .btn-outline-on-target:hover {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
  .btn-outline-on-target:focus, .btn-outline-on-target.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }
  .btn-outline-on-target.disabled, .btn-outline-on-target:disabled {
    color: #2378d6;
    background-color: transparent; }
  .btn-outline-on-target:not(:disabled):not(.disabled):active, .btn-outline-on-target:not(:disabled):not(.disabled).active,
  .show > .btn-outline-on-target.dropdown-toggle {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
    .btn-outline-on-target:not(:disabled):not(.disabled):active:focus, .btn-outline-on-target:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-on-target.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }

.btn-outline-button {
  color: #2378d6;
  background-color: transparent;
  background-image: none;
  border-color: #2378d6; }
  .btn-outline-button:hover {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
  .btn-outline-button:focus, .btn-outline-button.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }
  .btn-outline-button.disabled, .btn-outline-button:disabled {
    color: #2378d6;
    background-color: transparent; }
  .btn-outline-button:not(:disabled):not(.disabled):active, .btn-outline-button:not(:disabled):not(.disabled).active,
  .show > .btn-outline-button.dropdown-toggle {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
    .btn-outline-button:not(:disabled):not(.disabled):active:focus, .btn-outline-button:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-button.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }

.btn-outline-bluish {
  color: #2378d6;
  background-color: transparent;
  background-image: none;
  border-color: #2378d6; }
  .btn-outline-bluish:hover {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
  .btn-outline-bluish:focus, .btn-outline-bluish.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }
  .btn-outline-bluish.disabled, .btn-outline-bluish:disabled {
    color: #2378d6;
    background-color: transparent; }
  .btn-outline-bluish:not(:disabled):not(.disabled):active, .btn-outline-bluish:not(:disabled):not(.disabled).active,
  .show > .btn-outline-bluish.dropdown-toggle {
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }
    .btn-outline-bluish:not(:disabled):not(.disabled):active:focus, .btn-outline-bluish:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-bluish.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.5); }

.btn-outline-paleRed {
  color: #d45d5d;
  background-color: transparent;
  background-image: none;
  border-color: #d45d5d; }
  .btn-outline-paleRed:hover {
    color: #fff;
    background-color: #d45d5d;
    border-color: #d45d5d; }
  .btn-outline-paleRed:focus, .btn-outline-paleRed.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 93, 93, 0.5); }
  .btn-outline-paleRed.disabled, .btn-outline-paleRed:disabled {
    color: #d45d5d;
    background-color: transparent; }
  .btn-outline-paleRed:not(:disabled):not(.disabled):active, .btn-outline-paleRed:not(:disabled):not(.disabled).active,
  .show > .btn-outline-paleRed.dropdown-toggle {
    color: #fff;
    background-color: #d45d5d;
    border-color: #d45d5d; }
    .btn-outline-paleRed:not(:disabled):not(.disabled):active:focus, .btn-outline-paleRed:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-paleRed.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 93, 93, 0.5); }

.btn-outline-greyishBrown {
  color: #424242;
  background-color: transparent;
  background-image: none;
  border-color: #424242; }
  .btn-outline-greyishBrown:hover {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
  .btn-outline-greyishBrown:focus, .btn-outline-greyishBrown.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-outline-greyishBrown.disabled, .btn-outline-greyishBrown:disabled {
    color: #424242;
    background-color: transparent; }
  .btn-outline-greyishBrown:not(:disabled):not(.disabled):active, .btn-outline-greyishBrown:not(:disabled):not(.disabled).active,
  .show > .btn-outline-greyishBrown.dropdown-toggle {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
    .btn-outline-greyishBrown:not(:disabled):not(.disabled):active:focus, .btn-outline-greyishBrown:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-greyishBrown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2378d6;
  background-color: transparent; }
  .btn-link:hover {
    color: #185394;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background: #fafafa linear-gradient(180deg, #fbfbfb, #fafafa) repeat-x; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
    background-image: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(35, 120, 214, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b8d4f3; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(35, 120, 214, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(35, 120, 214, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(35, 120, 214, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #8cbaec;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(140, 186, 236, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #8cbaec;
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #8cbaec; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background: #e9ecef linear-gradient(180deg, #eceff1, #e9ecef) repeat-x;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(35, 120, 214, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(35, 120, 214, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(35, 120, 214, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background: #b8d4f3 linear-gradient(180deg, #c3dbf5, #b8d4f3) repeat-x; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background: #b8d4f3 linear-gradient(180deg, #c3dbf5, #b8d4f3) repeat-x; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background: #b8d4f3 linear-gradient(180deg, #c3dbf5, #b8d4f3) repeat-x; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2378d6; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fafafa;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2378d6;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #185394;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(35, 120, 214, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #2378d6;
  border-color: #2378d6; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #2378d6; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1c5faa; }

.badge-secondary {
  color: #fff;
  background-color: #283845; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #151e25; }

.badge-success {
  color: #fff;
  background-color: #45b649; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #37913a; }

.badge-info {
  color: #fff;
  background-color: #00c9ff; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #00a1cc; }

.badge-warning {
  color: #212529;
  background-color: #ffd700; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #ccac00; }

.badge-danger {
  color: #fff;
  background-color: #d45d5d; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #c93535; }

.badge-light {
  color: #212529;
  background-color: #fafafa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #e1e1e1; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.badge-buttonBorder {
  color: #212529;
  background-color: #e0e0e0; }
  .badge-buttonBorder[href]:hover, .badge-buttonBorder[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #c7c7c7; }

.badge-buttonBg {
  color: #212529;
  background-color: #fff; }
  .badge-buttonBg[href]:hover, .badge-buttonBg[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #e6e6e6; }

.badge-header {
  color: #fff;
  background-color: #283845; }
  .badge-header[href]:hover, .badge-header[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #151e25; }

.badge-arrowGrey {
  color: #212529;
  background-color: #d1d1d6; }
  .badge-arrowGrey[href]:hover, .badge-arrowGrey[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #b6b6be; }

.badge-inputGrey {
  color: #212529;
  background-color: #bdbdbd; }
  .badge-inputGrey[href]:hover, .badge-inputGrey[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #a4a4a4; }

.badge-white {
  color: #212529;
  background-color: #fff; }
  .badge-white[href]:hover, .badge-white[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #e6e6e6; }

.badge-gray-100 {
  color: #212529;
  background-color: #fafafa; }
  .badge-gray-100[href]:hover, .badge-gray-100[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #e1e1e1; }

.badge-breadcrumb-bg {
  color: #212529;
  background-color: #fafafa; }
  .badge-breadcrumb-bg[href]:hover, .badge-breadcrumb-bg[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #e1e1e1; }

.badge-formGrey {
  color: #fff;
  background-color: #757575; }
  .badge-formGrey[href]:hover, .badge-formGrey[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #5c5c5c; }

.badge-input-placeholder-color {
  color: #212529;
  background-color: #bdbdbd; }
  .badge-input-placeholder-color[href]:hover, .badge-input-placeholder-color[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #a4a4a4; }

.badge-input-plaintext-color {
  color: #fff;
  background-color: #424242; }
  .badge-input-plaintext-color[href]:hover, .badge-input-plaintext-color[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #292929; }

.badge-purple {
  color: #fff;
  background-color: #5f5073; }
  .badge-purple[href]:hover, .badge-purple[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #463b55; }

.badge-aquamarine {
  color: #212529;
  background-color: #4fe9b3; }
  .badge-aquamarine[href]:hover, .badge-aquamarine[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #22e39f; }

.badge-paleGreen {
  color: #212529;
  background-color: #c4d437; }
  .badge-paleGreen[href]:hover, .badge-paleGreen[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #a4b226; }

.badge-paleOrange {
  color: #212529;
  background-color: #fa7b46; }
  .badge-paleOrange[href]:hover, .badge-paleOrange[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #f95814; }

.badge-lightBlueGrey {
  color: #212529;
  background-color: #f1f6fa; }
  .badge-lightBlueGrey[href]:hover, .badge-lightBlueGrey[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #cbdeed; }

.badge-darkBlueGrey {
  color: #fff;
  background-color: #283845; }
  .badge-darkBlueGrey[href]:hover, .badge-darkBlueGrey[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #151e25; }

.badge-bluishGrey {
  color: #fff;
  background-color: #7c8f9e; }
  .badge-bluishGrey[href]:hover, .badge-bluishGrey[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #627685; }

.badge-transparent {
  color: #fff;
  background-color: rgba(0, 0, 0, 0); }
  .badge-transparent[href]:hover, .badge-transparent[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0); }

.badge-behind {
  color: #212529;
  background-color: #fa7b46; }
  .badge-behind[href]:hover, .badge-behind[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #f95814; }

.badge-exceeding {
  color: #212529;
  background-color: #4fe9b3; }
  .badge-exceeding[href]:hover, .badge-exceeding[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #22e39f; }

.badge-on-target {
  color: #fff;
  background-color: #2378d6; }
  .badge-on-target[href]:hover, .badge-on-target[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1c5faa; }

.badge-button {
  color: #fff;
  background-color: #2378d6; }
  .badge-button[href]:hover, .badge-button[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1c5faa; }

.badge-bluish {
  color: #fff;
  background-color: #2378d6; }
  .badge-bluish[href]:hover, .badge-bluish[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1c5faa; }

.badge-paleRed {
  color: #fff;
  background-color: #d45d5d; }
  .badge-paleRed[href]:hover, .badge-paleRed[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #c93535; }

.badge-greyishBrown {
  color: #fff;
  background-color: #424242; }
  .badge-greyishBrown[href]:hover, .badge-greyishBrown[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #292929; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #123e6f;
  background: #d3e4f7 linear-gradient(180deg, #dae8f8, #d3e4f7) repeat-x;
  border-color: #c1d9f4; }
  .alert-primary hr {
    border-top-color: #abccf0; }
  .alert-primary .alert-link {
    color: #0b2543; }

.alert-secondary {
  color: #151d24;
  background: #d4d7da linear-gradient(180deg, #dadde0, #d4d7da) repeat-x;
  border-color: #c3c7cb; }
  .alert-secondary hr {
    border-top-color: #b5babf; }
  .alert-secondary .alert-link {
    color: #020304; }

.alert-success {
  color: #245f26;
  background: #daf0db linear-gradient(180deg, #e0f2e0, #daf0db) repeat-x;
  border-color: #cbebcc; }
  .alert-success hr {
    border-top-color: #b9e4ba; }
  .alert-success .alert-link {
    color: #163a17; }

.alert-info {
  color: #006985;
  background: #ccf4ff linear-gradient(180deg, #d4f6ff, #ccf4ff) repeat-x;
  border-color: #b8f0ff; }
  .alert-info hr {
    border-top-color: #9febff; }
  .alert-info .alert-link {
    color: #004152; }

.alert-warning {
  color: #857000;
  background: #fff7cc linear-gradient(180deg, #fff8d4, #fff7cc) repeat-x;
  border-color: #fff4b8; }
  .alert-warning hr {
    border-top-color: #fff09f; }
  .alert-warning .alert-link {
    color: #524500; }

.alert-danger {
  color: #6e3030;
  background: #f6dfdf linear-gradient(180deg, #f7e4e4, #f6dfdf) repeat-x;
  border-color: #f3d2d2; }
  .alert-danger hr {
    border-top-color: #eebebe; }
  .alert-danger .alert-link {
    color: #4a2121; }

.alert-light {
  color: #828282;
  background: #fefefe linear-gradient(180deg, #fefefe, #fefefe) repeat-x;
  border-color: #fefefe; }
  .alert-light hr {
    border-top-color: #f1f1f1; }
  .alert-light .alert-link {
    color: dimgray; }

.alert-dark {
  color: #1b1e21;
  background: #d6d8d9 linear-gradient(180deg, #dcdedf, #d6d8d9) repeat-x;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-buttonBorder {
  color: #747474;
  background: #f9f9f9 linear-gradient(180deg, #fafafa, #f9f9f9) repeat-x;
  border-color: #f6f6f6; }
  .alert-buttonBorder hr {
    border-top-color: #e9e9e9; }
  .alert-buttonBorder .alert-link {
    color: #5b5b5b; }

.alert-buttonBg {
  color: #858585;
  background: white linear-gradient(180deg, white, white) repeat-x;
  border-color: white; }
  .alert-buttonBg hr {
    border-top-color: #f2f2f2; }
  .alert-buttonBg .alert-link {
    color: #6c6c6c; }

.alert-header {
  color: #151d24;
  background: #d4d7da linear-gradient(180deg, #dadde0, #d4d7da) repeat-x;
  border-color: #c3c7cb; }
  .alert-header hr {
    border-top-color: #b5babf; }
  .alert-header .alert-link {
    color: #020304; }

.alert-arrowGrey {
  color: #6d6d6f;
  background: #f6f6f7 linear-gradient(180deg, #f7f7f8, #f6f6f7) repeat-x;
  border-color: #f2f2f4; }
  .alert-arrowGrey hr {
    border-top-color: #e4e4e8; }
  .alert-arrowGrey .alert-link {
    color: #545455; }

.alert-inputGrey {
  color: #626262;
  background: #f2f2f2 linear-gradient(180deg, #f4f4f4, #f2f2f2) repeat-x;
  border-color: #ededed; }
  .alert-inputGrey hr {
    border-top-color: #e0e0e0; }
  .alert-inputGrey .alert-link {
    color: #494949; }

.alert-white {
  color: #858585;
  background: white linear-gradient(180deg, white, white) repeat-x;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.alert-gray-100 {
  color: #828282;
  background: #fefefe linear-gradient(180deg, #fefefe, #fefefe) repeat-x;
  border-color: #fefefe; }
  .alert-gray-100 hr {
    border-top-color: #f1f1f1; }
  .alert-gray-100 .alert-link {
    color: dimgray; }

.alert-breadcrumb-bg {
  color: #828282;
  background: #fefefe linear-gradient(180deg, #fefefe, #fefefe) repeat-x;
  border-color: #fefefe; }
  .alert-breadcrumb-bg hr {
    border-top-color: #f1f1f1; }
  .alert-breadcrumb-bg .alert-link {
    color: dimgray; }

.alert-formGrey {
  color: #3d3d3d;
  background: #e3e3e3 linear-gradient(180deg, #e7e7e7, #e3e3e3) repeat-x;
  border-color: #d8d8d8; }
  .alert-formGrey hr {
    border-top-color: #cbcbcb; }
  .alert-formGrey .alert-link {
    color: #242424; }

.alert-input-placeholder-color {
  color: #626262;
  background: #f2f2f2 linear-gradient(180deg, #f4f4f4, #f2f2f2) repeat-x;
  border-color: #ededed; }
  .alert-input-placeholder-color hr {
    border-top-color: #e0e0e0; }
  .alert-input-placeholder-color .alert-link {
    color: #494949; }

.alert-input-plaintext-color {
  color: #222222;
  background: #d9d9d9 linear-gradient(180deg, #dfdfdf, #d9d9d9) repeat-x;
  border-color: #cacaca; }
  .alert-input-plaintext-color hr {
    border-top-color: #bdbdbd; }
  .alert-input-plaintext-color .alert-link {
    color: #090909; }

.alert-purple {
  color: #312a3c;
  background: #dfdce3 linear-gradient(180deg, #e4e1e7, #dfdce3) repeat-x;
  border-color: #d2ced8; }
  .alert-purple hr {
    border-top-color: #c5c0cd; }
  .alert-purple .alert-link {
    color: #19151e; }

.alert-aquamarine {
  color: #29795d;
  background: #dcfbf0 linear-gradient(180deg, #e1fcf2, #dcfbf0) repeat-x;
  border-color: #cef9ea; }
  .alert-aquamarine hr {
    border-top-color: #b7f6e0; }
  .alert-aquamarine .alert-link {
    color: #1c5340; }

.alert-paleGreen {
  color: #666e1d;
  background: #f3f6d7 linear-gradient(180deg, #f5f7dd, #f3f6d7) repeat-x;
  border-color: #eef3c7; }
  .alert-paleGreen hr {
    border-top-color: #e8efb2; }
  .alert-paleGreen .alert-link {
    color: #414612; }

.alert-paleOrange {
  color: #824024;
  background: #fee5da linear-gradient(180deg, #fee9e0, #fee5da) repeat-x;
  border-color: #fedacb; }
  .alert-paleOrange hr {
    border-top-color: #fec8b2; }
  .alert-paleOrange .alert-link {
    color: #5a2c19; }

.alert-lightBlueGrey {
  color: #7d8082;
  background: #fcfdfe linear-gradient(180deg, #fcfdfe, #fcfdfe) repeat-x;
  border-color: #fbfcfe; }
  .alert-lightBlueGrey hr {
    border-top-color: #e7edf9; }
  .alert-lightBlueGrey .alert-link {
    color: #646668; }

.alert-darkBlueGrey {
  color: #151d24;
  background: #d4d7da linear-gradient(180deg, #dadde0, #d4d7da) repeat-x;
  border-color: #c3c7cb; }
  .alert-darkBlueGrey hr {
    border-top-color: #b5babf; }
  .alert-darkBlueGrey .alert-link {
    color: #020304; }

.alert-bluishGrey {
  color: #404a52;
  background: #e5e9ec linear-gradient(180deg, #e9ecef, #e5e9ec) repeat-x;
  border-color: #dae0e4; }
  .alert-bluishGrey hr {
    border-top-color: #cbd4d9; }
  .alert-bluishGrey .alert-link {
    color: #2a3035; }

.alert-transparent {
  color: rgba(0, 0, 0, 0.48);
  background: rgba(255, 255, 255, 0.8) linear-gradient(180deg, rgba(255, 255, 255, 0.83), rgba(255, 255, 255, 0.8)) repeat-x;
  border-color: rgba(255, 255, 255, 0.72); }
  .alert-transparent hr {
    border-top-color: rgba(242, 242, 242, 0.72); }
  .alert-transparent .alert-link {
    color: rgba(0, 0, 0, 0.48); }

.alert-behind {
  color: #824024;
  background: #fee5da linear-gradient(180deg, #fee9e0, #fee5da) repeat-x;
  border-color: #fedacb; }
  .alert-behind hr {
    border-top-color: #fec8b2; }
  .alert-behind .alert-link {
    color: #5a2c19; }

.alert-exceeding {
  color: #29795d;
  background: #dcfbf0 linear-gradient(180deg, #e1fcf2, #dcfbf0) repeat-x;
  border-color: #cef9ea; }
  .alert-exceeding hr {
    border-top-color: #b7f6e0; }
  .alert-exceeding .alert-link {
    color: #1c5340; }

.alert-on-target {
  color: #123e6f;
  background: #d3e4f7 linear-gradient(180deg, #dae8f8, #d3e4f7) repeat-x;
  border-color: #c1d9f4; }
  .alert-on-target hr {
    border-top-color: #abccf0; }
  .alert-on-target .alert-link {
    color: #0b2543; }

.alert-button {
  color: #123e6f;
  background: #d3e4f7 linear-gradient(180deg, #dae8f8, #d3e4f7) repeat-x;
  border-color: #c1d9f4; }
  .alert-button hr {
    border-top-color: #abccf0; }
  .alert-button .alert-link {
    color: #0b2543; }

.alert-bluish {
  color: #123e6f;
  background: #d3e4f7 linear-gradient(180deg, #dae8f8, #d3e4f7) repeat-x;
  border-color: #c1d9f4; }
  .alert-bluish hr {
    border-top-color: #abccf0; }
  .alert-bluish .alert-link {
    color: #0b2543; }

.alert-paleRed {
  color: #6e3030;
  background: #f6dfdf linear-gradient(180deg, #f7e4e4, #f6dfdf) repeat-x;
  border-color: #f3d2d2; }
  .alert-paleRed hr {
    border-top-color: #eebebe; }
  .alert-paleRed .alert-link {
    color: #4a2121; }

.alert-greyishBrown {
  color: #222222;
  background: #d9d9d9 linear-gradient(180deg, #dfdfdf, #d9d9d9) repeat-x;
  border-color: #cacaca; }
  .alert-greyishBrown hr {
    border-top-color: #bdbdbd; }
  .alert-greyishBrown .alert-link {
    color: #090909; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2378d6;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #fafafa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2378d6;
    border-color: #2378d6; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #123e6f;
  background-color: #c1d9f4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #123e6f;
    background-color: #abccf0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #123e6f;
    border-color: #123e6f; }

.list-group-item-secondary {
  color: #151d24;
  background-color: #c3c7cb; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #151d24;
    background-color: #b5babf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #151d24;
    border-color: #151d24; }

.list-group-item-success {
  color: #245f26;
  background-color: #cbebcc; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #245f26;
    background-color: #b9e4ba; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #245f26;
    border-color: #245f26; }

.list-group-item-info {
  color: #006985;
  background-color: #b8f0ff; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #006985;
    background-color: #9febff; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #006985;
    border-color: #006985; }

.list-group-item-warning {
  color: #857000;
  background-color: #fff4b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #857000;
    background-color: #fff09f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #857000;
    border-color: #857000; }

.list-group-item-danger {
  color: #6e3030;
  background-color: #f3d2d2; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6e3030;
    background-color: #eebebe; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6e3030;
    border-color: #6e3030; }

.list-group-item-light {
  color: #828282;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #828282;
    background-color: #f1f1f1; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-buttonBorder {
  color: #747474;
  background-color: #f6f6f6; }
  .list-group-item-buttonBorder.list-group-item-action:hover, .list-group-item-buttonBorder.list-group-item-action:focus {
    color: #747474;
    background-color: #e9e9e9; }
  .list-group-item-buttonBorder.list-group-item-action.active {
    color: #fff;
    background-color: #747474;
    border-color: #747474; }

.list-group-item-buttonBg {
  color: #858585;
  background-color: white; }
  .list-group-item-buttonBg.list-group-item-action:hover, .list-group-item-buttonBg.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-buttonBg.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-header {
  color: #151d24;
  background-color: #c3c7cb; }
  .list-group-item-header.list-group-item-action:hover, .list-group-item-header.list-group-item-action:focus {
    color: #151d24;
    background-color: #b5babf; }
  .list-group-item-header.list-group-item-action.active {
    color: #fff;
    background-color: #151d24;
    border-color: #151d24; }

.list-group-item-arrowGrey {
  color: #6d6d6f;
  background-color: #f2f2f4; }
  .list-group-item-arrowGrey.list-group-item-action:hover, .list-group-item-arrowGrey.list-group-item-action:focus {
    color: #6d6d6f;
    background-color: #e4e4e8; }
  .list-group-item-arrowGrey.list-group-item-action.active {
    color: #fff;
    background-color: #6d6d6f;
    border-color: #6d6d6f; }

.list-group-item-inputGrey {
  color: #626262;
  background-color: #ededed; }
  .list-group-item-inputGrey.list-group-item-action:hover, .list-group-item-inputGrey.list-group-item-action:focus {
    color: #626262;
    background-color: #e0e0e0; }
  .list-group-item-inputGrey.list-group-item-action.active {
    color: #fff;
    background-color: #626262;
    border-color: #626262; }

.list-group-item-white {
  color: #858585;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-gray-100 {
  color: #828282;
  background-color: #fefefe; }
  .list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
    color: #828282;
    background-color: #f1f1f1; }
  .list-group-item-gray-100.list-group-item-action.active {
    color: #fff;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-breadcrumb-bg {
  color: #828282;
  background-color: #fefefe; }
  .list-group-item-breadcrumb-bg.list-group-item-action:hover, .list-group-item-breadcrumb-bg.list-group-item-action:focus {
    color: #828282;
    background-color: #f1f1f1; }
  .list-group-item-breadcrumb-bg.list-group-item-action.active {
    color: #fff;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-formGrey {
  color: #3d3d3d;
  background-color: #d8d8d8; }
  .list-group-item-formGrey.list-group-item-action:hover, .list-group-item-formGrey.list-group-item-action:focus {
    color: #3d3d3d;
    background-color: #cbcbcb; }
  .list-group-item-formGrey.list-group-item-action.active {
    color: #fff;
    background-color: #3d3d3d;
    border-color: #3d3d3d; }

.list-group-item-input-placeholder-color {
  color: #626262;
  background-color: #ededed; }
  .list-group-item-input-placeholder-color.list-group-item-action:hover, .list-group-item-input-placeholder-color.list-group-item-action:focus {
    color: #626262;
    background-color: #e0e0e0; }
  .list-group-item-input-placeholder-color.list-group-item-action.active {
    color: #fff;
    background-color: #626262;
    border-color: #626262; }

.list-group-item-input-plaintext-color {
  color: #222222;
  background-color: #cacaca; }
  .list-group-item-input-plaintext-color.list-group-item-action:hover, .list-group-item-input-plaintext-color.list-group-item-action:focus {
    color: #222222;
    background-color: #bdbdbd; }
  .list-group-item-input-plaintext-color.list-group-item-action.active {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }

.list-group-item-purple {
  color: #312a3c;
  background-color: #d2ced8; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #312a3c;
    background-color: #c5c0cd; }
  .list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #312a3c;
    border-color: #312a3c; }

.list-group-item-aquamarine {
  color: #29795d;
  background-color: #cef9ea; }
  .list-group-item-aquamarine.list-group-item-action:hover, .list-group-item-aquamarine.list-group-item-action:focus {
    color: #29795d;
    background-color: #b7f6e0; }
  .list-group-item-aquamarine.list-group-item-action.active {
    color: #fff;
    background-color: #29795d;
    border-color: #29795d; }

.list-group-item-paleGreen {
  color: #666e1d;
  background-color: #eef3c7; }
  .list-group-item-paleGreen.list-group-item-action:hover, .list-group-item-paleGreen.list-group-item-action:focus {
    color: #666e1d;
    background-color: #e8efb2; }
  .list-group-item-paleGreen.list-group-item-action.active {
    color: #fff;
    background-color: #666e1d;
    border-color: #666e1d; }

.list-group-item-paleOrange {
  color: #824024;
  background-color: #fedacb; }
  .list-group-item-paleOrange.list-group-item-action:hover, .list-group-item-paleOrange.list-group-item-action:focus {
    color: #824024;
    background-color: #fec8b2; }
  .list-group-item-paleOrange.list-group-item-action.active {
    color: #fff;
    background-color: #824024;
    border-color: #824024; }

.list-group-item-lightBlueGrey {
  color: #7d8082;
  background-color: #fbfcfe; }
  .list-group-item-lightBlueGrey.list-group-item-action:hover, .list-group-item-lightBlueGrey.list-group-item-action:focus {
    color: #7d8082;
    background-color: #e7edf9; }
  .list-group-item-lightBlueGrey.list-group-item-action.active {
    color: #fff;
    background-color: #7d8082;
    border-color: #7d8082; }

.list-group-item-darkBlueGrey {
  color: #151d24;
  background-color: #c3c7cb; }
  .list-group-item-darkBlueGrey.list-group-item-action:hover, .list-group-item-darkBlueGrey.list-group-item-action:focus {
    color: #151d24;
    background-color: #b5babf; }
  .list-group-item-darkBlueGrey.list-group-item-action.active {
    color: #fff;
    background-color: #151d24;
    border-color: #151d24; }

.list-group-item-bluishGrey {
  color: #404a52;
  background-color: #dae0e4; }
  .list-group-item-bluishGrey.list-group-item-action:hover, .list-group-item-bluishGrey.list-group-item-action:focus {
    color: #404a52;
    background-color: #cbd4d9; }
  .list-group-item-bluishGrey.list-group-item-action.active {
    color: #fff;
    background-color: #404a52;
    border-color: #404a52; }

.list-group-item-transparent {
  color: rgba(0, 0, 0, 0.48);
  background-color: rgba(255, 255, 255, 0.72); }
  .list-group-item-transparent.list-group-item-action:hover, .list-group-item-transparent.list-group-item-action:focus {
    color: rgba(0, 0, 0, 0.48);
    background-color: rgba(242, 242, 242, 0.72); }
  .list-group-item-transparent.list-group-item-action.active {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.48);
    border-color: rgba(0, 0, 0, 0.48); }

.list-group-item-behind {
  color: #824024;
  background-color: #fedacb; }
  .list-group-item-behind.list-group-item-action:hover, .list-group-item-behind.list-group-item-action:focus {
    color: #824024;
    background-color: #fec8b2; }
  .list-group-item-behind.list-group-item-action.active {
    color: #fff;
    background-color: #824024;
    border-color: #824024; }

.list-group-item-exceeding {
  color: #29795d;
  background-color: #cef9ea; }
  .list-group-item-exceeding.list-group-item-action:hover, .list-group-item-exceeding.list-group-item-action:focus {
    color: #29795d;
    background-color: #b7f6e0; }
  .list-group-item-exceeding.list-group-item-action.active {
    color: #fff;
    background-color: #29795d;
    border-color: #29795d; }

.list-group-item-on-target {
  color: #123e6f;
  background-color: #c1d9f4; }
  .list-group-item-on-target.list-group-item-action:hover, .list-group-item-on-target.list-group-item-action:focus {
    color: #123e6f;
    background-color: #abccf0; }
  .list-group-item-on-target.list-group-item-action.active {
    color: #fff;
    background-color: #123e6f;
    border-color: #123e6f; }

.list-group-item-button {
  color: #123e6f;
  background-color: #c1d9f4; }
  .list-group-item-button.list-group-item-action:hover, .list-group-item-button.list-group-item-action:focus {
    color: #123e6f;
    background-color: #abccf0; }
  .list-group-item-button.list-group-item-action.active {
    color: #fff;
    background-color: #123e6f;
    border-color: #123e6f; }

.list-group-item-bluish {
  color: #123e6f;
  background-color: #c1d9f4; }
  .list-group-item-bluish.list-group-item-action:hover, .list-group-item-bluish.list-group-item-action:focus {
    color: #123e6f;
    background-color: #abccf0; }
  .list-group-item-bluish.list-group-item-action.active {
    color: #fff;
    background-color: #123e6f;
    border-color: #123e6f; }

.list-group-item-paleRed {
  color: #6e3030;
  background-color: #f3d2d2; }
  .list-group-item-paleRed.list-group-item-action:hover, .list-group-item-paleRed.list-group-item-action:focus {
    color: #6e3030;
    background-color: #eebebe; }
  .list-group-item-paleRed.list-group-item-action.active {
    color: #fff;
    background-color: #6e3030;
    border-color: #6e3030; }

.list-group-item-greyishBrown {
  color: #222222;
  background-color: #cacaca; }
  .list-group-item-greyishBrown.list-group-item-action:hover, .list-group-item-greyishBrown.list-group-item-action:focus {
    color: #222222;
    background-color: #bdbdbd; }
  .list-group-item-greyishBrown.list-group-item-action.active {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      color: #000;
      text-decoration: none;
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      transition: none; } }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-next {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #2378d6 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1c5faa !important; }

.bg-secondary {
  background-color: #283845 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #151e25 !important; }

.bg-success {
  background-color: #45b649 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #37913a !important; }

.bg-info {
  background-color: #00c9ff !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #00a1cc !important; }

.bg-warning {
  background-color: #ffd700 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ccac00 !important; }

.bg-danger {
  background-color: #d45d5d !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c93535 !important; }

.bg-light {
  background-color: #fafafa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e1e1e1 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-buttonBorder {
  background-color: #e0e0e0 !important; }

a.bg-buttonBorder:hover, a.bg-buttonBorder:focus,
button.bg-buttonBorder:hover,
button.bg-buttonBorder:focus {
  background-color: #c7c7c7 !important; }

.bg-buttonBg {
  background-color: #fff !important; }

a.bg-buttonBg:hover, a.bg-buttonBg:focus,
button.bg-buttonBg:hover,
button.bg-buttonBg:focus {
  background-color: #e6e6e6 !important; }

.bg-header {
  background-color: #283845 !important; }

a.bg-header:hover, a.bg-header:focus,
button.bg-header:hover,
button.bg-header:focus {
  background-color: #151e25 !important; }

.bg-arrowGrey {
  background-color: #d1d1d6 !important; }

a.bg-arrowGrey:hover, a.bg-arrowGrey:focus,
button.bg-arrowGrey:hover,
button.bg-arrowGrey:focus {
  background-color: #b6b6be !important; }

.bg-inputGrey {
  background-color: #bdbdbd !important; }

a.bg-inputGrey:hover, a.bg-inputGrey:focus,
button.bg-inputGrey:hover,
button.bg-inputGrey:focus {
  background-color: #a4a4a4 !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-gray-100 {
  background-color: #fafafa !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #e1e1e1 !important; }

.bg-breadcrumb-bg {
  background-color: #fafafa !important; }

a.bg-breadcrumb-bg:hover, a.bg-breadcrumb-bg:focus,
button.bg-breadcrumb-bg:hover,
button.bg-breadcrumb-bg:focus {
  background-color: #e1e1e1 !important; }

.bg-formGrey {
  background-color: #757575 !important; }

a.bg-formGrey:hover, a.bg-formGrey:focus,
button.bg-formGrey:hover,
button.bg-formGrey:focus {
  background-color: #5c5c5c !important; }

.bg-input-placeholder-color {
  background-color: #bdbdbd !important; }

a.bg-input-placeholder-color:hover, a.bg-input-placeholder-color:focus,
button.bg-input-placeholder-color:hover,
button.bg-input-placeholder-color:focus {
  background-color: #a4a4a4 !important; }

.bg-input-plaintext-color {
  background-color: #424242 !important; }

a.bg-input-plaintext-color:hover, a.bg-input-plaintext-color:focus,
button.bg-input-plaintext-color:hover,
button.bg-input-plaintext-color:focus {
  background-color: #292929 !important; }

.bg-purple {
  background-color: #5f5073 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #463b55 !important; }

.bg-aquamarine {
  background-color: #4fe9b3 !important; }

a.bg-aquamarine:hover, a.bg-aquamarine:focus,
button.bg-aquamarine:hover,
button.bg-aquamarine:focus {
  background-color: #22e39f !important; }

.bg-paleGreen {
  background-color: #c4d437 !important; }

a.bg-paleGreen:hover, a.bg-paleGreen:focus,
button.bg-paleGreen:hover,
button.bg-paleGreen:focus {
  background-color: #a4b226 !important; }

.bg-paleOrange {
  background-color: #fa7b46 !important; }

a.bg-paleOrange:hover, a.bg-paleOrange:focus,
button.bg-paleOrange:hover,
button.bg-paleOrange:focus {
  background-color: #f95814 !important; }

.bg-lightBlueGrey {
  background-color: #f1f6fa !important; }

a.bg-lightBlueGrey:hover, a.bg-lightBlueGrey:focus,
button.bg-lightBlueGrey:hover,
button.bg-lightBlueGrey:focus {
  background-color: #cbdeed !important; }

.bg-darkBlueGrey {
  background-color: #283845 !important; }

a.bg-darkBlueGrey:hover, a.bg-darkBlueGrey:focus,
button.bg-darkBlueGrey:hover,
button.bg-darkBlueGrey:focus {
  background-color: #151e25 !important; }

.bg-bluishGrey {
  background-color: #7c8f9e !important; }

a.bg-bluishGrey:hover, a.bg-bluishGrey:focus,
button.bg-bluishGrey:hover,
button.bg-bluishGrey:focus {
  background-color: #627685 !important; }

.bg-transparent {
  background-color: rgba(0, 0, 0, 0) !important; }

a.bg-transparent:hover, a.bg-transparent:focus,
button.bg-transparent:hover,
button.bg-transparent:focus {
  background-color: rgba(0, 0, 0, 0) !important; }

.bg-behind {
  background-color: #fa7b46 !important; }

a.bg-behind:hover, a.bg-behind:focus,
button.bg-behind:hover,
button.bg-behind:focus {
  background-color: #f95814 !important; }

.bg-exceeding {
  background-color: #4fe9b3 !important; }

a.bg-exceeding:hover, a.bg-exceeding:focus,
button.bg-exceeding:hover,
button.bg-exceeding:focus {
  background-color: #22e39f !important; }

.bg-on-target {
  background-color: #2378d6 !important; }

a.bg-on-target:hover, a.bg-on-target:focus,
button.bg-on-target:hover,
button.bg-on-target:focus {
  background-color: #1c5faa !important; }

.bg-button {
  background-color: #2378d6 !important; }

a.bg-button:hover, a.bg-button:focus,
button.bg-button:hover,
button.bg-button:focus {
  background-color: #1c5faa !important; }

.bg-bluish {
  background-color: #2378d6 !important; }

a.bg-bluish:hover, a.bg-bluish:focus,
button.bg-bluish:hover,
button.bg-bluish:focus {
  background-color: #1c5faa !important; }

.bg-paleRed {
  background-color: #d45d5d !important; }

a.bg-paleRed:hover, a.bg-paleRed:focus,
button.bg-paleRed:hover,
button.bg-paleRed:focus {
  background-color: #c93535 !important; }

.bg-greyishBrown {
  background-color: #424242 !important; }

a.bg-greyishBrown:hover, a.bg-greyishBrown:focus,
button.bg-greyishBrown:hover,
button.bg-greyishBrown:focus {
  background-color: #292929 !important; }

.bg-gradient-primary {
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x !important; }

.bg-gradient-secondary {
  background: #283845 linear-gradient(180deg, #485661, #283845) repeat-x !important; }

.bg-gradient-success {
  background: #45b649 linear-gradient(180deg, #61c164, #45b649) repeat-x !important; }

.bg-gradient-info {
  background: #00c9ff linear-gradient(180deg, #26d1ff, #00c9ff) repeat-x !important; }

.bg-gradient-warning {
  background: #ffd700 linear-gradient(180deg, #ffdd26, #ffd700) repeat-x !important; }

.bg-gradient-danger {
  background: #d45d5d linear-gradient(180deg, #da7575, #d45d5d) repeat-x !important; }

.bg-gradient-light {
  background: #fafafa linear-gradient(180deg, #fbfbfb, #fafafa) repeat-x !important; }

.bg-gradient-dark {
  background: #343a40 linear-gradient(180deg, #52585d, #343a40) repeat-x !important; }

.bg-gradient-buttonBorder {
  background: #e0e0e0 linear-gradient(180deg, #e5e5e5, #e0e0e0) repeat-x !important; }

.bg-gradient-buttonBg {
  background: #fff linear-gradient(180deg, white, #fff) repeat-x !important; }

.bg-gradient-header {
  background: #283845 linear-gradient(180deg, #485661, #283845) repeat-x !important; }

.bg-gradient-arrowGrey {
  background: #d1d1d6 linear-gradient(180deg, #d8d8dc, #d1d1d6) repeat-x !important; }

.bg-gradient-inputGrey {
  background: #bdbdbd linear-gradient(180deg, #c7c7c7, #bdbdbd) repeat-x !important; }

.bg-gradient-white {
  background: #fff linear-gradient(180deg, white, #fff) repeat-x !important; }

.bg-gradient-gray-100 {
  background: #fafafa linear-gradient(180deg, #fbfbfb, #fafafa) repeat-x !important; }

.bg-gradient-breadcrumb-bg {
  background: #fafafa linear-gradient(180deg, #fbfbfb, #fafafa) repeat-x !important; }

.bg-gradient-formGrey {
  background: #757575 linear-gradient(180deg, #8a8a8a, #757575) repeat-x !important; }

.bg-gradient-input-placeholder-color {
  background: #bdbdbd linear-gradient(180deg, #c7c7c7, #bdbdbd) repeat-x !important; }

.bg-gradient-input-plaintext-color {
  background: #424242 linear-gradient(180deg, #5e5e5e, #424242) repeat-x !important; }

.bg-gradient-purple {
  background: #5f5073 linear-gradient(180deg, #776a88, #5f5073) repeat-x !important; }

.bg-gradient-aquamarine {
  background: #4fe9b3 linear-gradient(180deg, #69ecbe, #4fe9b3) repeat-x !important; }

.bg-gradient-paleGreen {
  background: #c4d437 linear-gradient(180deg, #cdda55, #c4d437) repeat-x !important; }

.bg-gradient-paleOrange {
  background: #fa7b46 linear-gradient(180deg, #fb8f62, #fa7b46) repeat-x !important; }

.bg-gradient-lightBlueGrey {
  background: #f1f6fa linear-gradient(180deg, #f3f7fb, #f1f6fa) repeat-x !important; }

.bg-gradient-darkBlueGrey {
  background: #283845 linear-gradient(180deg, #485661, #283845) repeat-x !important; }

.bg-gradient-bluishGrey {
  background: #7c8f9e linear-gradient(180deg, #90a0ad, #7c8f9e) repeat-x !important; }

.bg-gradient-transparent {
  background: rgba(0, 0, 0, 0) linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0)) repeat-x !important; }

.bg-gradient-behind {
  background: #fa7b46 linear-gradient(180deg, #fb8f62, #fa7b46) repeat-x !important; }

.bg-gradient-exceeding {
  background: #4fe9b3 linear-gradient(180deg, #69ecbe, #4fe9b3) repeat-x !important; }

.bg-gradient-on-target {
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x !important; }

.bg-gradient-button {
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x !important; }

.bg-gradient-bluish {
  background: #2378d6 linear-gradient(180deg, #448cdc, #2378d6) repeat-x !important; }

.bg-gradient-paleRed {
  background: #d45d5d linear-gradient(180deg, #da7575, #d45d5d) repeat-x !important; }

.bg-gradient-greyishBrown {
  background: #424242 linear-gradient(180deg, #5e5e5e, #424242) repeat-x !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2378d6 !important; }

.border-secondary {
  border-color: #283845 !important; }

.border-success {
  border-color: #45b649 !important; }

.border-info {
  border-color: #00c9ff !important; }

.border-warning {
  border-color: #ffd700 !important; }

.border-danger {
  border-color: #d45d5d !important; }

.border-light {
  border-color: #fafafa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-buttonBorder {
  border-color: #e0e0e0 !important; }

.border-buttonBg {
  border-color: #fff !important; }

.border-header {
  border-color: #283845 !important; }

.border-arrowGrey {
  border-color: #d1d1d6 !important; }

.border-inputGrey {
  border-color: #bdbdbd !important; }

.border-white {
  border-color: #fff !important; }

.border-gray-100 {
  border-color: #fafafa !important; }

.border-breadcrumb-bg {
  border-color: #fafafa !important; }

.border-formGrey {
  border-color: #757575 !important; }

.border-input-placeholder-color {
  border-color: #bdbdbd !important; }

.border-input-plaintext-color {
  border-color: #424242 !important; }

.border-purple {
  border-color: #5f5073 !important; }

.border-aquamarine {
  border-color: #4fe9b3 !important; }

.border-paleGreen {
  border-color: #c4d437 !important; }

.border-paleOrange {
  border-color: #fa7b46 !important; }

.border-lightBlueGrey {
  border-color: #f1f6fa !important; }

.border-darkBlueGrey {
  border-color: #283845 !important; }

.border-bluishGrey {
  border-color: #7c8f9e !important; }

.border-transparent {
  border-color: rgba(0, 0, 0, 0) !important; }

.border-behind {
  border-color: #fa7b46 !important; }

.border-exceeding {
  border-color: #4fe9b3 !important; }

.border-on-target {
  border-color: #2378d6 !important; }

.border-button {
  border-color: #2378d6 !important; }

.border-bluish {
  border-color: #2378d6 !important; }

.border-paleRed {
  border-color: #d45d5d !important; }

.border-greyishBrown {
  border-color: #424242 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.125rem 0.25rem 0.0625rem rgba(40, 56, 69, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #2378d6 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1c5faa !important; }

.text-secondary {
  color: #283845 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #151e25 !important; }

.text-success {
  color: #45b649 !important; }

a.text-success:hover, a.text-success:focus {
  color: #37913a !important; }

.text-info {
  color: #00c9ff !important; }

a.text-info:hover, a.text-info:focus {
  color: #00a1cc !important; }

.text-warning {
  color: #ffd700 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ccac00 !important; }

.text-danger {
  color: #d45d5d !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #c93535 !important; }

.text-light {
  color: #fafafa !important; }

a.text-light:hover, a.text-light:focus {
  color: #e1e1e1 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-buttonBorder {
  color: #e0e0e0 !important; }

a.text-buttonBorder:hover, a.text-buttonBorder:focus {
  color: #c7c7c7 !important; }

.text-buttonBg {
  color: #fff !important; }

a.text-buttonBg:hover, a.text-buttonBg:focus {
  color: #e6e6e6 !important; }

.text-header {
  color: #283845 !important; }

a.text-header:hover, a.text-header:focus {
  color: #151e25 !important; }

.text-arrowGrey {
  color: #d1d1d6 !important; }

a.text-arrowGrey:hover, a.text-arrowGrey:focus {
  color: #b6b6be !important; }

.text-inputGrey {
  color: #bdbdbd !important; }

a.text-inputGrey:hover, a.text-inputGrey:focus {
  color: #a4a4a4 !important; }

.text-white {
  color: #fff !important; }

a.text-white:hover, a.text-white:focus {
  color: #e6e6e6 !important; }

.text-gray-100 {
  color: #fafafa !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #e1e1e1 !important; }

.text-breadcrumb-bg {
  color: #fafafa !important; }

a.text-breadcrumb-bg:hover, a.text-breadcrumb-bg:focus {
  color: #e1e1e1 !important; }

.text-formGrey {
  color: #757575 !important; }

a.text-formGrey:hover, a.text-formGrey:focus {
  color: #5c5c5c !important; }

.text-input-placeholder-color {
  color: #bdbdbd !important; }

a.text-input-placeholder-color:hover, a.text-input-placeholder-color:focus {
  color: #a4a4a4 !important; }

.text-input-plaintext-color {
  color: #424242 !important; }

a.text-input-plaintext-color:hover, a.text-input-plaintext-color:focus {
  color: #292929 !important; }

.text-purple {
  color: #5f5073 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #463b55 !important; }

.text-aquamarine {
  color: #4fe9b3 !important; }

a.text-aquamarine:hover, a.text-aquamarine:focus {
  color: #22e39f !important; }

.text-paleGreen {
  color: #c4d437 !important; }

a.text-paleGreen:hover, a.text-paleGreen:focus {
  color: #a4b226 !important; }

.text-paleOrange {
  color: #fa7b46 !important; }

a.text-paleOrange:hover, a.text-paleOrange:focus {
  color: #f95814 !important; }

.text-lightBlueGrey {
  color: #f1f6fa !important; }

a.text-lightBlueGrey:hover, a.text-lightBlueGrey:focus {
  color: #cbdeed !important; }

.text-darkBlueGrey {
  color: #283845 !important; }

a.text-darkBlueGrey:hover, a.text-darkBlueGrey:focus {
  color: #151e25 !important; }

.text-bluishGrey {
  color: #7c8f9e !important; }

a.text-bluishGrey:hover, a.text-bluishGrey:focus {
  color: #627685 !important; }

.text-transparent {
  color: rgba(0, 0, 0, 0) !important; }

a.text-transparent:hover, a.text-transparent:focus {
  color: rgba(0, 0, 0, 0) !important; }

.text-behind {
  color: #fa7b46 !important; }

a.text-behind:hover, a.text-behind:focus {
  color: #f95814 !important; }

.text-exceeding {
  color: #4fe9b3 !important; }

a.text-exceeding:hover, a.text-exceeding:focus {
  color: #22e39f !important; }

.text-on-target {
  color: #2378d6 !important; }

a.text-on-target:hover, a.text-on-target:focus {
  color: #1c5faa !important; }

.text-button {
  color: #2378d6 !important; }

a.text-button:hover, a.text-button:focus {
  color: #1c5faa !important; }

.text-bluish {
  color: #2378d6 !important; }

a.text-bluish:hover, a.text-bluish:focus {
  color: #1c5faa !important; }

.text-paleRed {
  color: #d45d5d !important; }

a.text-paleRed:hover, a.text-paleRed:focus {
  color: #c93535 !important; }

.text-greyishBrown {
  color: #424242 !important; }

a.text-greyishBrown:hover, a.text-greyishBrown:focus {
  color: #292929 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: landscape; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Assistant, sans-serif;
  font-weight: 400; }

.tbv-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden; }
  .tbv-app a {
    text-decoration: none; }
  .tbv-app a:hover {
    text-decoration: none; }
  .tbv-app h1,
  .tbv-app h2,
  .tbv-app h3 {
    color: #283845; }
  .tbv-app .breadcrumb-item {
    color: #283845; }
    .tbv-app .breadcrumb-item:hover {
      color: #d4d7da; }
  .tbv-app .bg-purple {
    background: #5f5073; }
  .tbv-app .bg-green {
    background: #c4d437; }
  .tbv-app .bg-orange {
    background: #fa7b46; }
  .tbv-app .bg-lightBlueGrey {
    background: #f1f6fa; }

form {
  color: #7c8f9e; }
  form label,
  form h6,
  form span {
    margin-bottom: 0;
    font-size: .75rem;
    font-weight: 600; }

ol {
  padding-left: 0;
  list-style-position: inside; }

hr {
  border-top: 1px solid #283845; }

.pl-15px {
  padding-left: 15px; }

.pl-30px {
  padding-left: 30px; }

.mr-15px {
  margin-right: 15px; }

.ml-15px {
  margin-right: 15px; }

.link {
  cursor: pointer; }

.tbv-box-shadow {
  box-shadow: 0 0.125rem 0.25rem 0.0625rem rgba(40, 56, 69, 0.05); }

.bottom-right {
  right: 0;
  bottom: 0; }

.bottom-left {
  bottom: 0;
  left: 0;
  transform: translateY(50%); }

.flex-basis-0 {
  flex-basis: 0; }

@media (max-width: 575.98px) {
  .mobile-column {
    flex-direction: column;
    text-align: center; }
  .flex-basis-0 {
    flex-basis: inherit; } }

@media print {
  table {
    font-size: 80%; } }

.tbv-popover-menu a {
  color: #283845;
  cursor: pointer;
  transition: color .2s ease; }
  .tbv-popover-menu a:hover {
    color: #2378d6;
    text-decoration: none; }

.nav-item .nav-link {
  display: flex;
  align-items: center;
  color: #283845; }
  .nav-item .nav-link.active {
    color: #2378d6;
    background: rgba(255, 255, 255, 0.23);
    opacity: 1; }

.sidebar .nav a {
  transition: all 150ms ease-in; }

.card-header {
  text-transform: uppercase; }

.badge:empty {
  display: inline-block; }

.form-group .react-datepicker-wrapper,
.form-group .react-datepicker__input-container {
  display: block;
  width: 100%; }

.tbv-sort .form-control,
.tbv-filter .form-control,
.card .form-control {
  color: #283845;
  background: none;
  border: none;
  border-bottom: 1.5px solid #f1f6fa;
  border-radius: 0; }
  .tbv-sort .form-control:focus,
  .tbv-filter .form-control:focus,
  .card .form-control:focus {
    border-bottom: 1.5px solid #4fe9b3;
    box-shadow: none; }

.tbv-sort .dropdown .dropdown-menu,
.tbv-filter .dropdown .dropdown-menu,
.card .dropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto; }

.tbv-sort .dropdown .dropdown-toggle,
.tbv-filter .dropdown .dropdown-toggle,
.card .dropdown .dropdown-toggle {
  font-weight: 400;
  color: #283845;
  background: none;
  border: none;
  border-bottom: 1.5px solid #f1f6fa;
  border-radius: 0; }
  .tbv-sort .dropdown .dropdown-toggle:focus,
  .tbv-filter .dropdown .dropdown-toggle:focus,
  .card .dropdown .dropdown-toggle:focus {
    box-shadow: none; }
  .tbv-sort .dropdown .dropdown-toggle:active,
  .tbv-filter .dropdown .dropdown-toggle:active,
  .card .dropdown .dropdown-toggle:active {
    color: #283845;
    background: #fff;
    border-color: #f1f6fa; }
  .tbv-sort .dropdown .dropdown-toggle:active:focus,
  .tbv-filter .dropdown .dropdown-toggle:active:focus,
  .card .dropdown .dropdown-toggle:active:focus {
    box-shadow: none; }

.tbv-add-note {
  font-size: small; }
  .tbv-add-note .form-control {
    padding: .875rem;
    font-size: small;
    color: #7c8f9e;
    background: none;
    border: 1px solid #dee2e6;
    border-radius: 3px; }
    .tbv-add-note .form-control:focus {
      border: 1px solid #7c8f9e;
      box-shadow: none; }

.tbv-email-form input,
.tbv-phone-form input {
  max-width: 8rem;
  font-size: .875rem; }

.form-group {
  margin-bottom: 1.25rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .form-group .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 2px 0; }

.form-check-input {
  position: relative;
  margin: 0 .25rem 0 0;
  vertical-align: middle; }

.btn {
  font-size: .875rem;
  font-weight: 600;
  border-radius: 1.25rem; }

.small {
  font-size: 70%; }

.btn-primary {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background: #2378d6;
  border: none;
  border-radius: 1.25rem;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
  transition: all .2s ease-in-out;
  /* Scale up the box */
  /* Fade in the pseudo-element with the bigger shadow */ }
  .btn-primary::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    border: none;
    border-radius: 1.25rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: all .2s ease-in-out; }
  .btn-primary:focus {
    box-shadow: none; }
  .btn-primary:hover {
    color: #fff;
    transform: scale(1.02, 1.02); }
  .btn-primary:hover::after {
    color: #fff;
    opacity: 1; }
  .btn-primary:active {
    background-color: #1759a2;
    transition: none; }

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background: #2378d6; }

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none; }

.btn-outline-primary {
  color: #2378d6;
  background: #fff;
  border-color: #2378d6; }
  .btn-outline-primary:focus {
    box-shadow: none; }
  .btn-outline-primary:hover, .btn-outline-primary:active {
    color: #2378d6;
    background: #fff;
    border-color: #2378d6; }

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #2378d6;
  background: #fff;
  border-color: #2378d6; }

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none; }

.form-group.required label:not(:empty)::after {
  color: #d45d5d;
  content: " *"; }

.modal-content .modal-header {
  background: linear-gradient(318deg, #51ecb3, #134fe3) top/100vw 16.75rem no-repeat; }
  .modal-content .modal-header .close {
    color: #fff; }
    .modal-content .modal-header .close:hover {
      color: #fff; }

.modal-content .modal-footer {
  border-top: none; }

.xl-button {
  padding: .6rem 1.1rem;
  font-size: 1.1rem; }

.meeting-form-title {
  margin-bottom: 1.1rem;
  font-size: 1.5rem;
  font-weight: 800;
  color: #000; }

.card-img-left {
  border-radius: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card-img-right {
  border-radius: 0;
  border-top-right-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px); }

.tbv-profile .card,
.tbv-signup .card,
.tbv-home-right .card,
.tbv-add-client-right .card {
  max-width: 37.5rem;
  padding: 1.875rem 3.75rem 2.5rem 3.75rem;
  margin: auto;
  box-shadow: 0 0.125rem 0.25rem 0.0625rem rgba(40, 56, 69, 0.05); }

@media (max-width: 575.98px) {
  .tbv-signup .card,
  .tbv-home-right .card,
  .tbv-add-client-right .card {
    padding: .9375rem 1.875rem 1.25rem 1.875rem; } }

.tbv-privacy .card,
.tbv-tos .card {
  max-width: 70rem;
  padding: 1.875rem 3.75rem 2.5rem 3.75rem;
  margin: auto;
  background: #f1f6fa; }

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  transform-origin: top center;
  animation-name: swing; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animated.infinite {
  animation-iteration-count: infinite; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.loader {
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid #f1f6fa;
  border-bottom: 0.25rem solid;
  border-radius: 50%;
  animation: spin .75s infinite linear; }

.can-click {
  cursor: pointer; }

.overflow-hidden {
  overflow: hidden; }

.font-size-sm {
  font-size: 12px; }

.font-size-md {
  font-size: 16px; }

.font-size-lg {
  font-size: 20px; }

.font-size-xl {
  font-size: 24px; }

@media (min-width: 1200px) {
  .hidden-xl {
    display: none; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hidden-lg {
    display: none; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .hidden-md {
    display: none; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .hidden-sm {
    display: none; } }

@media (max-width: 575.98px) {
  .hidden-xs {
    display: none; } }

.modal-backdrop-light {
  background: #fafafa;
  opacity: 1; }
  .modal-backdrop-light.modal-backdrop.show {
    opacity: 1; }

.tbv-header {
  z-index: 9;
  height: 72px;
  margin-bottom: 0;
  box-shadow: 0 2px 6px 0 rgba(177, 194, 207, 0.29), 0 2px 2px 0 rgba(210, 223, 234, 0.58); }
  .tbv-header__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto; }
  .tbv-header__brand-name {
    font-size: 1.125em; }
  .tbv-header__brand-text {
    font-size: .875em; }

.tbv-content {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

.tbv-page__header {
  display: flex;
  align-items: flex-end;
  align-self: center; }
  @media (max-width: 767.98px) {
    .tbv-page__header {
      flex-direction: column;
      align-items: flex-start; } }

.tbv-page__title {
  padding-right: .5rem;
  margin-bottom: 1rem; }
  @media (max-width: 767.98px) {
    .tbv-page__title {
      margin-bottom: 0; } }

.tbv-page__breadcrumb {
  align-self: flex-end;
  padding: 0;
  text-transform: capitalize;
  background: #fafafa; }
  @media (max-width: 767.98px) {
    .tbv-page__breadcrumb {
      align-self: flex-start; } }

.tbv-page .row .col,
.tbv-page .row > [class^="col-"] {
  margin-bottom: 1rem; }

.tbv-phone-form {
  position: relative; }
  .tbv-phone-form__button {
    border-radius: 1.5rem; }

.tbv-header__brand-name {
  font-size: 18px; }

.tbv-header__brand-text {
  font-size: 14px; }

.tbv-landing-left {
  width: 100%;
  height: 100%; }
  .tbv-landing-left__hero {
    object-fit: contain; }
  .tbv-landing-left__hero-header img {
    padding-bottom: 40px; }

.tbv-user-select-buttons {
  width: 100%; }
  .tbv-user-select-buttons__icon-next {
    color: #d1d1d6; }
  .tbv-user-select-buttons .btn {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin-bottom: 1rem;
    background-color: #fff;
    border: none;
    box-shadow: 0 0.125rem 0.25rem 0.0625rem rgba(40, 56, 69, 0.05); }
  .tbv-user-select-buttons .btn:hover {
    background-color: #d3e4f7; }

.tbv-login-form .btn {
  font-size: 1rem;
  font-weight: 600; }

.supervisor-login {
  display: block;
  width: auto;
  padding: 9px 24px;
  margin: .8rem .5rem .8rem .5rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  background: rgba(232, 67, 147, 0.9);
  border-radius: 3rem; }
  .supervisor-login:hover {
    color: #fff;
    cursor: pointer;
    background: rgba(229, 44, 134, 0.9); }

.supervisor-divider {
  width: 100%;
  height: 0;
  margin: 30px 0 45px 0;
  text-align: center;
  border-top: 1px solid #ccc; }

.supervisor-divider__text {
  position: relative;
  top: -19px;
  display: inline-block;
  padding: 6px 12px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 100%; }

.tbv-signup-instructions {
  max-width: 24.5rem;
  margin: auto; }
  .tbv-signup-instructions__list-group-item {
    display: list-item;
    list-style: decimal inside; }

.tbv-forgot-password-form .btn {
  border-radius: 1.25rem; }

.tbv-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -224px;
  z-index: 5;
  width: 224px;
  font-weight: 200;
  color: #fff;
  background-position: center center;
  background-size: cover;
  transition: left .1s ease-in; }
  .tbv-sidebar + .tbv-content {
    transition: margin .1s ease-in; }
  .tbv-sidebar::after, .tbv-sidebar::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: ""; }
  .tbv-sidebar::before {
    background: #000;
    opacity: .33; }
  .tbv-sidebar::after {
    z-index: 3;
    background: #fafafa;
    opacity: 1; }
  .tbv-sidebar[data-image]::after {
    opacity: .77; }
  .tbv-sidebar__content {
    position: relative;
    z-index: 4;
    height: calc(100vh - 20px);
    padding-bottom: 30px;
    overflow: auto; }
  .tbv-sidebar__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover; }
  .tbv-sidebar__nav-item-collapse {
    display: flex;
    justify-content: space-between;
    cursor: pointer; }
  .tbv-sidebar__nav-item-icon {
    width: 1.5rem;
    height: 1.5rem;
    padding-right: .5rem;
    color: #283845; }
  .tbv-sidebar--open {
    left: 0;
    transition: left .2s ease-in; }
    .tbv-sidebar--open + .tbv-content {
      margin: 0 0 0 224px;
      transition: margin .2s ease-in; }
  .tbv-sidebar .nav {
    margin-top: 20px; }
    .tbv-sidebar .nav .nav-item {
      margin: 5px 10px;
      color: #fafafa;
      border-radius: 4px;
      opacity: .86; }
    .tbv-sidebar .nav .nav-link {
      color: #283845; }
      .tbv-sidebar .nav .nav-link.active {
        color: #2378d6;
        opacity: 1; }
      .tbv-sidebar .nav .nav-link:hover {
        background: #fefefe; }
        .tbv-sidebar .nav .nav-link:hover span {
          color: #283845;
          background: #fefefe; }

.tbv-notifications p {
  padding: 1rem 0 1.25rem 0;
  font-size: .75rem; }

.tbv-notifications-popover {
  border: none;
  border-radius: 0;
  box-shadow: 0 0.125rem 0.25rem 0.0625rem rgba(40, 56, 69, 0.05); }

.tbv-menu {
  color: #283845; }
  .tbv-menu.tbv-card {
    border-bottom: none; }
  .tbv-menu .card-body {
    padding: 0 1rem; }
  .tbv-menu .nav-link {
    padding: .75rem 1.3rem; }
  .tbv-menu .nav-link.active {
    border-bottom: 5px solid #2378d6; }
  .tbv-menu span {
    font-size: .75rem; }
  .tbv-menu hr {
    border-top-color: #f1f6fa; }
  .tbv-menu h6 {
    font-size: .875rem; }
  .tbv-menu__nav-item-collapse {
    cursor: pointer; }
  .tbv-menu__nav-item {
    cursor: pointer; }
  .tbv-menu a {
    transition: color .2s ease; }
    .tbv-menu a:hover {
      color: #2378d6; }

.tbv-admin-menu {
  color: #283845; }
  .tbv-admin-menu.tbv-card {
    border-bottom: none; }
  .tbv-admin-menu .card-body {
    padding: 0 1rem; }
  .tbv-admin-menu .nav-link {
    padding: .75rem 1.3rem; }
  .tbv-admin-menu .nav-link.active {
    border-bottom: 5px solid #2378d6; }
  .tbv-admin-menu span {
    font-size: .75rem; }
  .tbv-admin-menu hr {
    border-top-color: #f1f6fa; }
  .tbv-admin-menu h6 {
    font-size: .875rem; }
  .tbv-admin-menu__nav-item-collapse {
    cursor: pointer; }
  .tbv-admin-menu__nav-item {
    cursor: pointer; }
  .tbv-admin-menu a {
    transition: color .2s ease; }
    .tbv-admin-menu a:hover {
      color: #2378d6; }

.tbv-dash-header {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6; }
  .tbv-dash-header__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto; }

.tbv-dash-admin-title {
  margin-top: 1.7rem;
  margin-bottom: 2.6rem; }

.tbv-dash-title {
  height: 6rem;
  margin-top: 2.8rem;
  margin-bottom: 1.5rem;
  color: #283845; }
  .tbv-dash-title h1 {
    color: #fff; }

.tbv-dash-navbar {
  color: #283845; }
  .tbv-dash-navbar .form-control {
    color: #283845;
    background: none;
    border: none;
    border-bottom: 1px solid #283845;
    border-radius: 0; }
    .tbv-dash-navbar .form-control:focus {
      color: #283845;
      background: none;
      border-bottom: 1px solid #283845;
      box-shadow: none; }
    .tbv-dash-navbar .form-control::placeholder {
      color: #283845; }
  .tbv-dash-navbar button {
    color: #283845;
    border: 1px solid #283845; }
  .tbv-dash-navbar span {
    color: #283845; }

.tbv-dash .btn:hover,
.tbv-dash .btn-outline-primary:not(:disabled):not(.disabled):active,
.tbv-dash .btn-outline-primary:not(:disabled):not(.disabled).active,
.tbv-dash .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #283845;
  border-color: #283845; }

.tbv-dash .btn:focus,
.tbv-dash .btn:not(:disabled):not(.disabled):active:focus,
.tbv-dash .btn:not(:disabled):not(.disabled).active:focus,
.tbv-dash .show > .btn.dropdown-toggle:focus {
  box-shadow: none; }

.tbv-dash-white {
  background: linear-gradient(318deg, #51ecb3, #134fe3) top/100vw 16.75rem no-repeat; }
  .tbv-dash-white::before {
    position: absolute;
    left: -.25rem;
    width: 100%;
    height: 16.75rem;
    content: "";
    background: url("../assets/img/background/dashboard-bg.jpg");
    background-size: cover;
    opacity: .25; }
  .tbv-dash-white .tbv-back-button {
    padding: 10px 30px;
    font-weight: 600;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 3.25rem; }
  .tbv-dash-white .tbv-back-button:hover {
    color: #2378d6;
    background-color: #fff;
    border-color: #fff; }
  .tbv-dash-white .tbv-dash-navbar {
    color: #fff; }
    .tbv-dash-white .tbv-dash-navbar input {
      width: 12rem; }
    .tbv-dash-white .tbv-dash-navbar .form-control {
      color: #fff;
      border-bottom: 1px solid #fff; }
      .tbv-dash-white .tbv-dash-navbar .form-control:focus {
        color: #fff;
        border-bottom: 1px solid #fff; }
      .tbv-dash-white .tbv-dash-navbar .form-control::placeholder {
        color: #fff; }
    .tbv-dash-white .tbv-dash-navbar .tbv-add-client-btn {
      padding-right: 25px;
      padding-left: 25px;
      color: #fff;
      background-color: transparent;
      border: 1px solid #fff; }
      .tbv-dash-white .tbv-dash-navbar .tbv-add-client-btn:hover {
        color: #2378d6; }
    .tbv-dash-white .tbv-dash-navbar span {
      color: #fff; }
  .tbv-dash-white .tbv-dash-title {
    color: #fff; }
  .tbv-dash-white .tbv-add-client-btn:hover,
  .tbv-dash-white .btn-outline-primary:not(:disabled):not(.disabled):active,
  .tbv-dash-white .btn-outline-primary:not(:disabled):not(.disabled).active,
  .tbv-dash-white .show > .btn-outline-primary.dropdown-toggle {
    color: #2378d6;
    background-color: #fff;
    border-color: #fff; }
  .tbv-dash-white .btn:focus,
  .tbv-dash-white .btn:not(:disabled):not(.disabled):active:focus,
  .tbv-dash-white .btn:not(:disabled):not(.disabled).active:focus,
  .tbv-dash-white .show > .btn.dropdown-toggle:focus {
    box-shadow: none; }

.tbv-search-form {
  position: relative; }
  .tbv-search-form__icon-search {
    position: absolute;
    left: 8px; }
  .tbv-search-form__input {
    /* stylelint-disable-next-line */
    padding-left: 35px !important; }

.tbv-sort {
  width: 100%; }

.client-card-statuses {
  margin-top: .5rem; }
  .client-card-statuses > div {
    display: inline-block; }
    .client-card-statuses > div:nth-child(2) {
      margin: 0 1.5rem; }

.clients-filters > .clients-filter {
  display: inline-block;
  font-size: 28px;
  font-weight: 600; }
  .clients-filters > .clients-filter.active {
    border-bottom: 3px solid #000; }
  .clients-filters > .clients-filter:hover {
    cursor: pointer; }

.empty-clients-grid {
  padding: 140px 0; }
  .empty-clients-grid h1 {
    color: #9b9b9b; }

.tbv-client-detail__client-update-form {
  background: #fff; }

.tbv-client-detail .btn {
  border-radius: 40px; }

.tbv-client-detail .card-body .list-group-item {
  padding-right: 0;
  padding-left: 0;
  border: none; }

.tbv-card {
  border-top: none;
  border-right: none;
  border-bottom: .5rem solid;
  border-left: none;
  box-shadow: 0 0.125rem 0.25rem 0.0625rem rgba(40, 56, 69, 0.05); }
  .tbv-card h6 {
    font-size: .875rem; }

.tbv-card-short {
  height: 11.25rem; }

.tbv-card-tall {
  height: 15rem; }

.tbv-menu,
.tbv-admin-menu {
  height: auto; }

.tbv-contact-card {
  padding-top: 60px; }
  .tbv-contact-card img {
    top: 0;
    left: 50%;
    border: 8px solid #fff;
    border-radius: 50%;
    transform: translate(-50%, -33%); }

.empty-card-text {
  padding: 120px 0;
  font-size: 30px;
  color: #ccc; }

.tbv-dash-landing__stats-row .tbv-card {
  transition: box-shadow .5s, transform .5s; }
  .tbv-dash-landing__stats-row .tbv-card h1,
  .tbv-dash-landing__stats-row .tbv-card h6 {
    color: #424242; }
  .tbv-dash-landing__stats-row .tbv-card.selected::before {
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    width: 10px;
    height: 10px;
    content: "";
    background: #2378d6;
    border-radius: 50%; }
  .tbv-dash-landing__stats-row .tbv-card:hover:not(.selected) {
    position: relative;
    cursor: pointer;
    box-shadow: 0 0.5rem 0.8rem 0.1rem rgba(40, 56, 69, 0.08);
    transform: scale(1.02); }
    .tbv-dash-landing__stats-row .tbv-card:hover:not(.selected)::before {
      position: absolute;
      top: 10px;
      left: 10px;
      display: block;
      width: 10px;
      height: 10px;
      background: #d1d1d6;
      border-radius: 50%; }

.tbv-dash-landing .empty-attendance-table {
  padding: 150px 0;
  font-size: 38px;
  color: #d1d1d6; }

.tbv-dash-landing .tbv-attendance-chart .dropdown .dropdown-toggle,
.tbv-dash-landing .tbv-sort .dropdown .dropdown-toggle,
.tbv-dash-landing .tbv-filter .dropdown .dropdown-toggle,
.tbv-dash-landing .tbv-graph .dropdown .dropdown-toggle {
  color: #283845;
  border-bottom: none; }
  .tbv-dash-landing .tbv-attendance-chart .dropdown .dropdown-toggle:focus,
  .tbv-dash-landing .tbv-sort .dropdown .dropdown-toggle:focus,
  .tbv-dash-landing .tbv-filter .dropdown .dropdown-toggle:focus,
  .tbv-dash-landing .tbv-graph .dropdown .dropdown-toggle:focus {
    box-shadow: none; }
  .tbv-dash-landing .tbv-attendance-chart .dropdown .dropdown-toggle:active,
  .tbv-dash-landing .tbv-sort .dropdown .dropdown-toggle:active,
  .tbv-dash-landing .tbv-filter .dropdown .dropdown-toggle:active,
  .tbv-dash-landing .tbv-graph .dropdown .dropdown-toggle:active {
    color: #283845;
    background: #fff;
    border-color: #f1f6fa; }
  .tbv-dash-landing .tbv-attendance-chart .dropdown .dropdown-toggle:active:focus,
  .tbv-dash-landing .tbv-sort .dropdown .dropdown-toggle:active:focus,
  .tbv-dash-landing .tbv-filter .dropdown .dropdown-toggle:active:focus,
  .tbv-dash-landing .tbv-graph .dropdown .dropdown-toggle:active:focus {
    box-shadow: none; }

.tbv-legend div::before {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: .3875rem;
  margin-left: .3875rem;
  vertical-align: text-bottom;
  content: "";
  border-radius: .1875rem; }

.tbv-legend .tbv-legend-on-target::before {
  background: #2378d6; }

.tbv-legend .tbv-legend-exceeding::before {
  background: #4fe9b3; }

.tbv-legend .tbv-legend-behind::before {
  background: #fa7b46; }

.tbv-email-form {
  position: relative; }
  .tbv-email-form__button {
    border-radius: 1.5rem; }

.tbv-client-report .tbv-filter .dropdown .dropdown-toggle,
.tbv-client-report .tbv-graph .dropdown .dropdown-toggle {
  color: #283845;
  border-bottom: none; }
  .tbv-client-report .tbv-filter .dropdown .dropdown-toggle:focus,
  .tbv-client-report .tbv-graph .dropdown .dropdown-toggle:focus {
    box-shadow: none; }
  .tbv-client-report .tbv-filter .dropdown .dropdown-toggle:active,
  .tbv-client-report .tbv-graph .dropdown .dropdown-toggle:active {
    color: #283845;
    background: #fff;
    border-color: #f1f6fa; }
  .tbv-client-report .tbv-filter .dropdown .dropdown-toggle:active:focus,
  .tbv-client-report .tbv-graph .dropdown .dropdown-toggle:active:focus {
    box-shadow: none; }

.tbv-client-report .tbv-contact-card a {
  color: #283845; }
  .tbv-client-report .tbv-contact-card a svg {
    fill: #283845; }

.tbv-client-report .tbv-contact-card a:hover {
  color: #2378d6; }
  .tbv-client-report .tbv-contact-card a:hover svg {
    fill: #2378d6; }

.tbv-attendance table {
  font-size: 80%; }

.location-map-key {
  display: inline-block;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc; }

.attendance-table {
  width: 100%; }

.attendance-table__body {
  margin: 2rem 0;
  clear: both; }

.attendance-row {
  position: relative;
  display: flex;
  margin: .75rem 0; }
  .attendance-row:hover {
    cursor: pointer;
    background: #f7f9fc; }

.attendance-row__icon {
  position: relative;
  top: 8px;
  padding: 0 .3rem; }

.attendance-day-popover {
  display: flex;
  min-width: 250px; }

.attendance-row-popover {
  width: 200px; }

.attendance-row-link {
  display: inline;
  padding: 0;
  color: #283845; }
  .attendance-row-link:hover {
    color: #283845;
    /* stylelint-disable */
    text-decoration: underline !important;
    /* stylelint-enable */ }

.attendance-row__info {
  width: 200px;
  padding: .5rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .attendance-row__info .name {
    padding: 0 1rem; }

.attendance-row--behind::before {
  position: absolute;
  width: 5px;
  height: 100%;
  content: "";
  background: #fa7b46;
  border-radius: 0 3px 3px 0; }

.attendance-row--exceeding::before {
  position: absolute;
  width: 5px;
  height: 100%;
  content: "";
  background: #4fe9b3;
  border-radius: 0 3px 3px 0; }

.attendance-row--ontarget::before {
  position: absolute;
  width: 5px;
  height: 100%;
  content: "";
  background: #2378d6;
  border-radius: 0 3px 3px 0; }

.admin-meeting-search-form__form .form-control {
  border-bottom-color: #ccc; }

.admin-meeting-search-form__form .form-control::placeholder {
  color: #666; }

.admin-meeting-search-form__bg {
  min-height: 100%;
  padding: 30px;
  background: whitesmoke;
  border-radius: .25rem 0 .25rem 0; }
  .admin-meeting-search-form__bg > section {
    margin-bottom: 3rem;
    overflow: hidden; }

.admin-org-logo {
  display: block;
  width: 60%;
  margin: 0 auto; }

@media (max-width: 575px) {
  .admin-meeting-search-form__bg {
    position: relative;
    right: 0;
    left: 0;
    padding: 30px 30px 80px 30px; } }

.tbv-message-form__form {
  padding: 30px; }

.tbv-message-form__template-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 10px 10px;
  margin-top: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px; }
  .tbv-message-form__template-message:first-of-type {
    margin-top: 0; }

.tbv-message-form__template-message .msg-text {
  flex: 1;
  color: #828282; }

.tbv-message-form__template-message .select-template {
  width: 20px; }

.tbv-message-form__form .custom-message-box {
  height: 6rem; }

.confirm-message-modal .modal-title {
  font-weight: 600;
  color: #fff; }

.confirm-message-modal .modal-footer {
  justify-content: flex-start; }

.confirm-message-modal__msg {
  padding: 8px;
  background: #f6f6f6; }

.confirm-message-modal__confirm {
  margin-top: 1.1rem; }

.tbv-message-form__form .btn-primary::after,
.confirm-message-modal .modal-footer .btn-primary::after {
  position: relative; }

.chat-channel-container {
  min-width: 600px;
  max-width: 750px;
  margin: 0 auto;
  margin-top: -98px;
  background: #444753;
  border-radius: 5px; }

ul {
  list-style: none; }

.chat {
  float: left;
  width: 100%;
  color: #434651;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.25); }
  .chat .chat-header {
    padding: 20px;
    border-bottom: 2px solid #f2f5f8; }
    .chat .chat-header img {
      float: left; }
    .chat .chat-header .chat-about {
      float: left;
      padding-left: 10px;
      margin-top: 6px; }
    .chat .chat-header .chat-with {
      font-size: 16px;
      font-weight: 800; }
    .chat .chat-header .chat-num-messages {
      color: #92959e; }
  .chat .chat-history {
    height: 575px;
    padding: 30px 30px 20px;
    overflow-y: scroll;
    border-bottom: 2px solid #f2f5f8; }
    .chat .chat-history .message-data {
      margin-bottom: 15px; }
    .chat .chat-history .message-data-time {
      padding-left: 6px;
      color: #a8aab1; }
    .chat .chat-history .message {
      position: relative;
      width: 90%;
      padding: 18px 20px;
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 26px;
      color: #fff;
      border-radius: 7px; }
      .chat .chat-history .message::after {
        position: absolute;
        bottom: 100%;
        left: 7%;
        width: 0;
        height: 0;
        margin-left: -10px;
        pointer-events: none;
        content: " ";
        border: solid transparent;
        border-width: 10px;
        border-bottom-color: #6fcfc4; }
    .chat .chat-history .my-message {
      background: #6fcfc4; }
    .chat .chat-history .other-message {
      background: #4d81da; }
      .chat .chat-history .other-message::after {
        left: 93%;
        border-bottom-color: #4d81da; }
    .chat .chat-history .empty-history {
      margin-top: 15em;
      text-align: center; }
      .chat .chat-history .empty-history p {
        font-style: italic;
        color: #92959e; }
  .chat .chat-message {
    padding: 30px; }
    .chat .chat-message textarea {
      width: 100%;
      padding: 10px 20px;
      margin-bottom: 10px;
      font: 14px/22px Lato, Arial, sans-serif;
      resize: none;
      border: 2px solid #f2f5f8;
      border-radius: 5px;
      outline: none; }
    .chat .chat-message button {
      float: right;
      font-size: 16px;
      font-weight: 800;
      color: #4d81da;
      text-transform: uppercase;
      cursor: pointer;
      background: #fff;
      border: none; }
      .chat .chat-message button:hover, .chat .chat-message button:active {
        /* stylelint-disable */
        color: #2f6cd4 !important;
        background: #fff !important;
        /* stylelint-enable */ }

.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px; }

.online {
  color: #6fcfc4; }

.me {
  color: #4d81da; }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.float-right {
  float: right; }

.clearfix::after {
  display: block;
  height: 0;
  clear: both;
  font-size: 0;
  visibility: hidden;
  content: " "; }

@media (max-width: 1220px) {
  .chat-channel-container {
    margin-top: -20px; } }

.bg-gradient-theme {
  background: linear-gradient(to bottom, #2378d6, #283845); }

.bg-gradient-theme-top {
  background: linear-gradient(to top, #2378d6, #283845); }

.bg-gradient-theme-left {
  background: linear-gradient(to left, #2378d6, #283845); }

.bg-gradient-theme-right {
  background: linear-gradient(to right, #2378d6, #283845); }

.HomePage .tbv-home-right,
.ProfilePage .tbv-home-right {
  background: #f1f6fa; }
  .HomePage .tbv-home-right h2,
  .ProfilePage .tbv-home-right h2 {
    padding-bottom: 40px; }

.HomePage .tbv-home-left,
.ProfilePage .tbv-home-left {
  padding-top: 56px; }

.LogInPage,
.ForgotPasswordPage {
  height: 100%;
  background: linear-gradient(301deg, rgba(81, 236, 179, 0.5), rgba(19, 79, 227, 0.5)), url("../assets/img/background/desktop-login-bg.jpg");
  background-size: cover; }
  .LogInPage .tbv-home-right h2,
  .ForgotPasswordPage .tbv-home-right h2 {
    padding-bottom: 40px; }
  .LogInPage .tbv-home-left,
  .ForgotPasswordPage .tbv-home-left {
    padding-top: 56px; }

.SignUpPage {
  background: #f1f6fa; }
  .SignUpPage .tbv-signup h3 {
    padding: 65px 0 40px; }
  .SignUpPage .tbv-home-left {
    padding-top: 56px; }

.WelcomePage {
  height: 100%;
  background: #f1f6fa; }

.AddClientPage {
  background: #f1f6fa; }
  .AddClientPage .tbv-add-client-right h2 {
    padding-bottom: 40px; }
  .AddClientPage .tbv-add-client-left {
    padding-top: 56px; }

.ProfilePage .tbv-profile h3 {
  padding: 56px 0 15px 1.5rem; }

.ProfilePage .tbv-home-left {
  padding-top: 56px; }

.PrivacyPage,
.TermsOfServicePage {
  background: #f1f6fa; }
  .PrivacyPage h2,
  .TermsOfServicePage h2 {
    padding-top: 56px;
    padding-bottom: 40px; }
